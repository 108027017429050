import React, {Fragment} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  Link,
  ListItemSecondaryAction,
  Button,
  useMediaQuery,
  Box,
  Alert,
} from '@mui/material';
import CameraListRow from './CameraListRow';

const CamerasList = (props) => {
  const {
    site,
    onCameraEnableClick,
    siteHasSchedule,
    onCameraEdit,
    camerasList,
    setCamerasList,
    isVideoAlarmSupported,
    isEnvrAdmin,
  } = props;

  const handleOnCheckboxClick = async (camera) => {
    await onCameraEnableClick(camera);
    if (camera.motionAlarmEnabled) {
      setCamerasList(
        camerasList.map((cam) => {
          if (cam.id === camera.id) {
            return {
              ...cam,
              motionAlarmEnabled: false,
            };
          }
          return cam;
        }),
      );
    }
  };
  const getWarningContent = () => {
    let content;
    if (!isVideoAlarmSupported) {
      content = (
        <Typography>
          The appliance software version does not support video alarms or is
          unknown, please contact support for assistance.
        </Typography>
      );
    }
    if (isVideoAlarmSupported && camerasList.length === 0) {
      content = (
        <Typography>
          No Cameras enabled for this site. Visit the{' '}
          {isEnvrAdmin ? (
            <Link
              component={RouterLink}
              to={`/sites/${site.id}/settings/devices`}
            >
              Device Management
            </Link>
          ) : (
            <span>Device Management</span>
          )}{' '}
          page to enable cameras for display here.
        </Typography>
      );
    }
    return content;
  };

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
      }}
      name="cameras-card"
    >
      {!isVideoAlarmSupported || camerasList.length === 0 ? (
        getWarningContent()
      ) : (
        <Fragment>
          {smallScreen ? (
            <Alert severity="info">
              Camera Enablement is only available on destkop
            </Alert>
          ) : (
            <Typography pb={2} variant="body2">
              Enabling a camera could take up to 5 minutes where you cannot
              navigate away from the page
            </Typography>
          )}
          <Grid
            container
            justifyContent="space-between"
            paddingTop={1}
            paddingBottom={0.5}
          >
            {!smallScreen && (
              <Fragment>
                <Grid item md={2}>
                  <Typography variant="body1">Enabled</Typography>
                </Grid>
                <Grid item md={7}>
                  <Typography px={2} variant="body1">
                    Camera Name
                  </Typography>
                </Grid>
              </Fragment>
            )}
          </Grid>
          <Box
            name="cameras-list"
            sx={{display: 'grid', gridAutoRows: '1fr', gridAutoFlow: 'row'}}
          >
            {camerasList.map((camera) => {
              const cameraWithImg = {
                ...camera,
                img: `/api/v3/sensor_views/${camera.id}/thumbnail`,
              };
              return (
                <CameraListRow
                  smallScreen={smallScreen}
                  key={`camera-${camera.id}`}
                  checked={camera.motionAlarmEnabled}
                  camera={cameraWithImg}
                  onChange={() => handleOnCheckboxClick(camera)}
                  disableSelect={
                    !isEnvrAdmin || !siteHasSchedule || smallScreen
                  }
                >
                  {camera.motionAlarmEnabled && !smallScreen && (
                    <Grid item md={1}>
                      <ListItemSecondaryAction>
                        <Button
                          color="primary"
                          variant="outlined"
                          name={`camera-${camera.id}-edit`}
                          disabled={!isEnvrAdmin}
                          onClick={() => {
                            onCameraEdit(camera);
                          }}
                        >
                          EDIT
                        </Button>
                      </ListItemSecondaryAction>
                    </Grid>
                  )}
                </CameraListRow>
              );
            })}
          </Box>
        </Fragment>
      )}
    </Box>
  );
};
CamerasList.propTypes = {
  isEnvrAdmin: PropTypes.bool.isRequired,
  onCameraEnableClick: PropTypes.func.isRequired,
  siteHasSchedule: PropTypes.bool.isRequired,
  onCameraEdit: PropTypes.func.isRequired,
  camerasList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCamerasList: PropTypes.func.isRequired,
  isVideoAlarmSupported: PropTypes.bool.isRequired,
};

export default CamerasList;
