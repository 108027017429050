import React, {useCallback} from 'react';

import PropTypes from 'prop-types';
import {chunk} from 'lodash';
import {useTheme, useMediaQuery} from '@mui/material';
import {DateTime} from 'luxon';
import DashboardWidget from '../../../../shared/components/dashboardComponent/dashboardWidget';
import StackedColumnChartWidget from '../../../../shared/components/highcharts/stackedColumnChart';
import {getCategoryName, getColor} from '../../../../shared/util/ssp';
import {useSsp} from '../hooks';
import {getAlarmSummaryByDay} from '../../../../api/alarms';
import {alarmLabels} from '../../../sites/details/alarmEventsSummary/utils';
import {verificationStatuses} from '../sspUtils';

export const filtersType = Object.freeze({
  monthly: 'monthly',
  weekly: 'weekly',
  quarterly: 'quarterly',
});
const monthlyDaysCount = 30;
const getFilterType = (startDate, endDate) => {
  const days = Math.floor(endDate.diff(startDate, 'days').days);

  switch (true) {
    case days < monthlyDaysCount:
      return filtersType.weekly;
    case days === monthlyDaysCount:
      return filtersType.monthly;
    case days > monthlyDaysCount:
      return filtersType.quarterly;
    default:
      return filtersType.weekly;
  }
};

const categories = [
  verificationStatuses.yes,
  verificationStatuses.unverifiable,
  verificationStatuses.unclassified,
  verificationStatuses.no,
];

const formatDate = (dateString) =>
  DateTime.fromFormat(dateString, 'yyyy-MM-dd', {zone: 'UTC'}).toFormat(
    'dd MMM',
  );

const transformDataForQuarter = (data) => {
  return Object.values(data).map((category) => {
    const chunks = chunk(category.data, monthlyDaysCount);

    if (chunks.length > 3) {
      const fourthChunk = chunks.pop();
      chunks.at(-1).push(...fourthChunk);
    }

    const dataEntries = chunks.map((ch) => {
      const startDate = ch[0].name;
      const endDate = ch[ch.length - 1].name;
      const periodName = `${startDate} - ${endDate}`;

      const total = ch.reduce((sum, entry) => sum + entry.y, 0);

      return {
        y: total,
        name: periodName,
        drilldown: false,
      };
    });

    return {
      name: category.name,
      color: category.color,
      data: dataEntries,
    };
  });
};

const transformData = (results, filterType, theme) => {
  const transformedData = {};
  results.sort((a, b) => a - b);
  results.forEach((result) => {
    const {date} = result;

    categories.forEach((category) => {
      if (!transformedData[category]) {
        transformedData[category] = {
          name: getCategoryName(category),
          color: getColor(category, theme),
          data: [],
        };
      }
      const total = result[category];
      const dataEntry = {y: total, name: formatDate(date), drilldown: false};
      transformedData[category].data.push(dataEntry);
    });
  });

  return filterType === filtersType.quarterly
    ? transformDataForQuarter(transformedData)
    : Object.values(transformedData);
};

const TotalAlarmsWidget = ({startDate, endDate, onLegendClick, ...rest}) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const theme = useTheme();

  const filterType = getFilterType(startDate, endDate);
  const [alarmsByDayLoading, alarmsByDay] = useSsp(
    useCallback(
      (start, end, signal) =>
        getAlarmSummaryByDay(start, end, [alarmLabels.eventsOnly], signal),
      [],
    ),
    startDate,
    endDate,
    useCallback((value) => transformData(value.results, filterType, theme), [
      filterType,
      theme,
    ]),
  );

  const getTotalVisibleAlarms = (chartSeries) =>
    chartSeries.reduce(
      (sum, curr) =>
        curr.visible
          ? sum + curr.yData.reduce((acc, value) => acc + value, 0)
          : sum,
      0,
    );
  const fontSize = !isSmallScreen
    ? theme.typography.fontSize + 2
    : theme.typography.fontSize;
  const marginSpacing = {
    xs: theme.spacing(1, 0),
    sm: theme.spacing(1, 0),
    md: theme.spacing(1, 1, 0, 1),
    lg: theme.spacing(1, 1, 0, 1),
  };
  const rotation =
    filterType === filtersType.monthly || isSmallScreen ? -60 : 0;
  const getPointWidth = () => {
    if (isSmallScreen) {
      if (filterType === filtersType.monthly) {
        return 10;
      }
      return filterType !== filtersType.quarterly ? 20 : 60;
    }
    if (filterType === filtersType.monthly) {
      return 20;
    }
    return 60;
  };
  const chartContainerStyle = {
    style: {
      height: isLargeScreen ? '95%' : '100%',
      display: 'flex',
      alignItems: 'center',
    },
  };

  return (
    <DashboardWidget
      id="total-alarms"
      loading={alarmsByDayLoading}
      CardContentProps={{
        sx: {
          margin: marginSpacing,
          flex: 1,
        },
      }}
      CardProps={{sx: {padding: 0}}}
      BoxProps={{height: '100%'}}
      content={
        <StackedColumnChartWidget
          data={alarmsByDay}
          onLegendClick={onLegendClick}
          graphSettings={{
            widgetName: 'total-alarms',
            title: 'Total Alarms',
            chart: {
              events: {
                render(event) {
                  const {series} = event.target;
                  const totalNumber = getTotalVisibleAlarms(series);
                  event.target.setTitle({
                    text: `Total Alarms (${totalNumber})`,
                  });
                },
              },
            },
            pointWidth: getPointWidth(),
            xAxis: {
              allowDecimals: false,
              labels: {
                rotation,
                y: 20,
                style: {fontSize},
              },
            },
            legend: {
              itemStyle: {
                fontSize,
              },
              itemDistance: isSmallScreen ? 40 : 24,
              itemMarginTop: 16,
              margin: parseFloat(theme.spacing(isLargeScreen ? 3 : 0)),
            },
          }}
          containerProps={chartContainerStyle}
        />
      }
      {...rest}
    />
  );
};

TotalAlarmsWidget.propTypes = {
  startDate: PropTypes.instanceOf(DateTime).isRequired,
  endDate: PropTypes.instanceOf(DateTime).isRequired,
  onLegendClick: PropTypes.func,
};

TotalAlarmsWidget.defaultProps = {
  onLegendClick: undefined,
};

export default TotalAlarmsWidget;
