import React from 'react';

import {Route, Switch} from 'react-router-dom';
import GlobalErrorPage from '../globalErrorPage';
import {BI_REPORT_2} from '../../shared/util/allowed';
import PageLoader from '../../shared/components/pageLoader';
import CapabilitiesGuard from '../../shared/components/capabilitiesGuard';
import {
  getReports,
  getReportData,
  getSiteHierarchyData,
} from '../../api/reports';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import {getBiAlerts} from '../../api/users';
import {isNewNavigationWebEnabled} from '../../shared/util/user';
import {getPartners} from '../../api/domains';

const Loader = ({match, currentUser}) => {
  return (
    <Switch>
      {/* NEW REPORTS PAGE */}
      {currentUser.settings.enableNewReportsDetailPage && (
        <Route
          path={`${match.path}/${
            isNewNavigationWebEnabled(currentUser) ? ':tabAlias/' : ''
          }:reportId`}
          render={(renderProps) => {
            const {reportId} = renderProps.match.params;
            return (
              <CapabilitiesGuard
                allowed={[BI_REPORT_2]}
                renderDefault={() => <GlobalErrorPage error="NoAuth" />}
              >
                <PageLoader
                  page={() => import('./newReportsPage/Page')}
                  resources={{
                    reportId: () => reportId,
                    reportData: () => getReportData(reportId),
                    siteHierarchyData: () => getSiteHierarchyData(),
                  }}
                />
              </CapabilitiesGuard>
            );
          }}
        />
      )}

      <CapabilitiesGuard
        allowed={[BI_REPORT_2]}
        renderDefault={() => <GlobalErrorPage error="NoAuth" />}
      >
        <PageLoader
          page={() => import('./Page')}
          resources={{
            reports: getReports,
            alerts: () => getBiAlerts(currentUser.id),
            partners: () => getPartners(currentUser.domainId),
          }}
        />
      </CapabilitiesGuard>
    </Switch>
  );
};

export default withCurrentUser(Loader);
