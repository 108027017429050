import React, {Fragment, useEffect} from 'react';
import {DateTime} from 'luxon';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {getRiskiestTransactions} from '../../../../api/reports';
import Spinner from '../../../../shared/components/spinner';
import DashboardWidget from '../../../../shared/components/dashboardComponent/dashboardWidget';
import {useApi} from '../../../../shared/hooks';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: theme.spacing(1),
    display: 'inline-block',
  },
  header: {
    padding: [[theme.spacing(0.5), 0]],
  },
  riskRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  error: {
    textAlign: 'center',
    backgroundColor: theme.palette.error.main,
    padding: theme.spacing(1),
    color: theme.palette.common.white,
  },
}));

const formatDatetime = (isoString) =>
  DateTime.fromISO(isoString, {zone: 'utc'}).toLocaleString(
    DateTime.DATETIME_MED,
  );

const TransactionsRiskWidget = (props) => {
  const {rowSize = 'small', widgetConfig} = props;
  let results = [];

  const classes = useStyles(props);
  const [data, isLoading, isError, call] = useApi(getRiskiestTransactions);

  const handleRowClick = (url) => {
    if (!url) return;
    window.open(url);
  };
  useEffect(
    () => {
      call();
    },
    [call],
  );
  if (data) {
    results = data;
  }

  const getContent = () => {
    return (
      <div name="transaction-risk-widget">
        <Fragment>
          {isLoading ? (
            <Spinner size={40} color="primary" />
          ) : (
            <Fragment>
              {isError ? (
                <Typography variant="subtitle1" className={classes.error}>
                  Error loading transaction risk snapshot.
                </Typography>
              ) : (
                <Table
                  aria-label="Transactions risk level table"
                  size={rowSize}
                  name="transaction-risk-table"
                >
                  <TableHead name="transaction-risk-table-header">
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.header}
                        >
                          Amount
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.header}
                        >
                          Employee
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.header}
                        >
                          Type
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.header}
                        >
                          Date
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {results.map((t) => (
                      <TableRow
                        key={t.id}
                        name={`transaction-risk-${t.id}`}
                        hover
                        onClick={() => handleRowClick(t.reportUrl)}
                        className={classes.riskRow}
                      >
                        <TableCell
                          scope="row"
                          name={`transaction-risk-${t.id}-amount`}
                        >
                          <Typography variant="body1">
                            ${t.transactionTotal}
                          </Typography>
                        </TableCell>
                        <TableCell
                          scope="row"
                          name={`transaction-risk-${t.id}-employee`}
                        >
                          <Typography variant="body1">
                            {t.employeeName} [{t.employeeCode}]
                          </Typography>
                        </TableCell>
                        <TableCell
                          scope="row"
                          name={`transaction-risk-${t.id}-type`}
                        >
                          <Typography variant="body1">
                            {t.transactionType}
                          </Typography>
                        </TableCell>
                        <TableCell
                          scope="row"
                          name={`transaction-risk-${t.id}-datetime`}
                        >
                          <Typography variant="body1">
                            {formatDatetime(t.transactionEndTime)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Fragment>
          )}
        </Fragment>
      </div>
    );
  };

  return (
    <DashboardWidget
      id={widgetConfig.widgetReference}
      title={widgetConfig.title}
      loading={isLoading}
      content={getContent()}
      {...widgetConfig.properties}
    />
  );
};

export default TransactionsRiskWidget;
