import React from 'react';
import {Box, Grid} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import compose from 'lodash/flowRight';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import {getSite} from '../../../../api/sites';
import {flattenSitesListForSearch} from '../../../../shared/util/sites';
import {getSiteCameras} from '../../../../shared/util/cameras';
import SiteSelectionMenu from '../../../../shared/components/siteSelectionMenu/SiteSelectionMenu';
import {
  getAlarmCamerasBySite,
  getSiteAlarmStatus,
} from '../../../../api/alarms';
import {getCamerasBySiteId} from '../../../../api/cameras';
import {getSiteCloudArchiveSettings} from '../../../../api/cloudArchive';
import {VideoContextProvider} from '../../../../shared/contexts/video';
import PageLoader from '../../../../shared/components/pageLoader';
import {getAppliancesByParams, getAppliance} from '../../../../api/appliances';
import {useSiteSelector} from '../../../../shared/hooks';
import withUrlState from '../../../../shared/components/withUrlState';

const AlarmEventsSummaryNewNavigation = ({
  currentUser,
  urlState,
  setUrlState,
}) => {
  const [selectedSite, sitesList, reload, handleSiteSelect] = useSiteSelector(
    urlState,
    setUrlState,
  );

  return (
    <VideoContextProvider>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        data-cy="alarm-events-page-content"
      >
        <Grid container justifyContent="end" spacing={1} marginBottom={3}>
          <Grid item xs={12} md={4} lg={3}>
            <SiteSelectionMenu
              siteSearchList={
                !isEmpty(sitesList)
                  ? flattenSitesListForSearch(sitesList, sitesList.name)
                  : []
              }
              title={selectedSite.name || 'Select...'}
              rootCategory={sitesList}
              setCurrentSite={async (e) => {
                if (e.currentSite) {
                  handleSiteSelect(e);
                }
              }}
              dropdownProps={{variant: 'outlined', size: 'small'}}
              autofocus={!selectedSite.id}
            />
          </Grid>
        </Grid>
        {!isEmpty(selectedSite.id) && !isEmpty(selectedSite.name) && (
          <PageLoader
            key="alarmEventsSummary"
            reload={reload}
            page={() => import('../alarmEventsSummary')}
            resources={{
              site: async () => getSite(selectedSite.id),
              cameras: () => getSiteCameras(currentUser, selectedSite.id),
              alarmCameras: async () => {
                const cams = await getAlarmCamerasBySite(selectedSite.id);
                return cams.results;
              },
              siteCameras: async () => getCamerasBySiteId(selectedSite.id),
              appliances: async () => {
                const fetchedAppliances = (await getAppliancesByParams({
                  siteId: selectedSite.id,
                  active: true,
                })).results;
                return Promise.all(
                  fetchedAppliances.map(async (a) => ({
                    ...(await getAppliance(a.id)),
                    cameraDiscovery: a.cameraDiscovery,
                  })),
                );
              },
              siteCloudArchiveEnabled: async () =>
                currentUser.settings.enableCloudArchive2
                  ? getSiteCloudArchiveSettings(selectedSite.id)
                  : {enabled: false},
              siteAlarmMode: async () =>
                (await getSiteAlarmStatus(selectedSite.id)).statuses.find(
                  ({isActive}) => isActive,
                ).status,
            }}
          />
        )}
      </Box>
    </VideoContextProvider>
  );
};

export default compose(
  withCurrentUser,
  withUrlState(),
)(AlarmEventsSummaryNewNavigation);
