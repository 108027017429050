import React from 'react';
import {Link} from '@mui/material';
import {checkPlatformSupport} from '../../../../shared/util/general';

import DashboardSection from '../../../../shared/components/dashboardComponent/dashboardSection';

const actions = [
  {
    id: 'link_button',
    label: 'See full SSP landing page',
    ButtonProps: {component: Link, href: '/ui/ssp'},
  },
];

const SspHeaderWidget = ({widgetConfig}) => {
  return (
    <DashboardSection
      id={widgetConfig.widgetReference}
      title={widgetConfig.title || 'Smart Site Protection'}
      subtitle={widgetConfig.subtitle || 'Alarms from last 7 days'}
      actions={
        checkPlatformSupport({
          supportsDesktop: true,
          minMobileVersion: '3.33.0',
        })
          ? actions
          : []
      }
      {...widgetConfig.properties}
    />
  );
};
export default SspHeaderWidget;
