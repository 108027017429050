import React, {useRef} from 'react';
import PropTypes from 'prop-types';

import {Fade, Popper, List, Paper} from '@mui/material';

import SecondNavSubListItem from './SecondNavSubListItem';
import {useOnClickOutside} from '../../../hooks';

const SecondNavSubSideList = ({
  subNavigationState,
  onSubNavClose,
  onSubNavItemClick,
  onMouseLeave,
  onMouseEnter,
}) => {
  const popperRef = useRef(null);
  const anchorRef = {current: subNavigationState.anchorEl};
  useOnClickOutside(popperRef, anchorRef, onSubNavClose);

  return (
    <Popper
      data-cy="sidebar-sub-navigation"
      open={subNavigationState.open}
      anchorEl={subNavigationState.anchorEl}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      sx={{zIndex: (theme) => theme.zIndex.drawer}}
      placement="right-start"
      transition
    >
      {({TransitionProps}) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper
            sx={(theme) => ({
              marginX: 0.5,
              boxShadow: theme.shadows[16],
            })}
            ref={popperRef}
          >
            <List>
              {subNavigationState.subNavItems?.map((item) => (
                <SecondNavSubListItem
                  key={`sub-nav-link-${item.path}`}
                  to={item.path}
                  icon={item.icon}
                  primaryTitleText={item.primaryTitleText}
                  shortTitleText={item.shortTitleText}
                  onClick={() =>
                    onSubNavItemClick(item.primaryTitleText, item.name)
                  }
                />
              ))}
            </List>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

SecondNavSubSideList.propTypes = {
  /**
   * State of side sub navigation item on bigger screens (720px and more)
   * This is only for side navigation elements
   */
  subNavigationState: PropTypes.shape({
    /**
     * Boolean for indicating if item is active
     */
    open: PropTypes.bool,
    /**
     *  Anchor element used to set position of sub menu popover
     */
    anchorEl: PropTypes.shape({}),
    /**
     * Array of sub navigation items (objects) to display in the pop up menu
     * Objects should contain:
     *
     * path - String containing path directing where to navigate on click
     *   Should contain path element which needs to be added to parent path
     *   Switch component in the same instance of pageTemplateResponsive should handle the same route
     *   Props is passed to NavLink component 'to' property
     *
     * capabilities - Array of strings defining capabilities needed to show item in nav bar
     *
     * id - unique identifier
     *
     * name - identifying name for items. It's required that all subItems
     *   should have the same value as parent item
     *
     * icon - Node with icon to display in sub nav menu item
     *
     * primaryTitleText - String containing display name of sub nav menu item
     *   primary title is used on bottom navigation item when no shortTitleText is provided
     *
     * shortTitleText - String containing display name of sub nav menu item
     *   short title is used on bottom item if provided
     */
    subNavItems: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string,
        capabilities: PropTypes.arrayOf(PropTypes.string),
        id: PropTypes.string,
        name: PropTypes.string,
        icon: PropTypes.node,
        primaryTitleText: PropTypes.string,
        shortTitleText: PropTypes.string,
      }),
    ),
  }).isRequired,
  /**
   *  Callback function to handle clicks on sub-nav-items
   */
  onSubNavItemClick: PropTypes.func.isRequired,
  /**
   *  Callback function handle closing sub-nav-items
   */
  onSubNavClose: PropTypes.func.isRequired,
  /**
   *  Callback function handle on hover action when mouse leaves item area
   */
  onMouseLeave: PropTypes.func.isRequired,
  /**
   *  Callback function handle on hover action when mouse enters item area
   */
  onMouseEnter: PropTypes.func.isRequired,
};

export default SecondNavSubSideList;
