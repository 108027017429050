import React, {useEffect, useState} from 'react';
import compose from 'lodash/flowRight';

import {useMediaQuery} from '@mui/material';
import {getDomain} from '../../api/domains';
import allowed, {BI_REPORT_2, BI_REPORT_ADMIN} from '../../shared/util/allowed';

import withUrlState from '../../shared/components/withUrlState';
import {withLogger} from '../../shared/components/logger';
import LoadingScreen from '../../shared/components/loadingScreen';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import {isMobileApp} from '../../shared/util/general';

import PageTemplateResponsive from '../../shared/components/pageTemplateResponsive';
import GridList from './GridList';
import MobileGrid from './hardwareHealthMobile/MobileGrid';

function HardwareHealth(props) {
  const {logger} = props;
  const [caStatusAllowed, setCaStatusAllowed] = useState(false);
  const [loading, setLoading] = useState(true);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const posAllowed = allowed(props.currentUser, [BI_REPORT_2, BI_REPORT_ADMIN]);

  useEffect(
    () => {
      let ignore = false;

      getDomain(props.currentUser.domainId)
        .then((response) => {
          if (ignore) {
            return;
          }

          if (response) {
            const hasCloudArchiveCapabilities = response.paidCapabilities.includes(
              'CloudArchiveUser',
            );
            setCaStatusAllowed(hasCloudArchiveCapabilities);
            setLoading(false);
          }
        })
        .catch((error) => {
          logger.error(
            'Failed to load domain data. Cloud archive allowed status unknown',
            {},
            error,
          );
          setLoading(false);
        });

      return () => {
        ignore = true;
      };
    },
    [props.currentUser.domainId, logger],
  );

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <PageTemplateResponsive
      title={!isMobileApp() && 'System Health'}
      PageHeaderProps={{title: 'System Health Status'}}
    >
      {isSmallScreen ? (
        <MobileGrid
          {...props}
          posAllowed={posAllowed}
          caStatusAllowed={caStatusAllowed}
        />
      ) : (
        <GridList
          {...props}
          posAllowed={posAllowed}
          caStatusAllowed={caStatusAllowed}
        />
      )}
    </PageTemplateResponsive>
  );
}

export default compose(
  withUrlState(),
  withCurrentUser,
  withLogger,
)(HardwareHealth);
