import React from 'react';
import IFrameWrapper from '../../shared/components/iFrameWrapper';
import PageTemplateResponsive from '../../shared/components/pageTemplateResponsive';
import CapabilitiesGuard from '../../shared/components/capabilitiesGuard';
import {BI_REPORT_2} from '../../shared/util/allowed';
import GlobalErrorPage from '../globalErrorPage';
import {getHost} from '../../shared/components/iFrameWrapper/hostUtil';

const notificationPreferencesUrl = `https://${getHost()}/app/#/preferences/notifications?embedded=true`;

const NotificationPreferences = () => {
  return (
    <CapabilitiesGuard
      allowed={[BI_REPORT_2]}
      renderDefault={() => <GlobalErrorPage error="NoAuth" />}
    >
      <PageTemplateResponsive
        PageContentProps={{
          marginY: 0,
          marginX: 0,
        }}
      >
        <IFrameWrapper
          src={notificationPreferencesUrl}
          title="notification-preferences"
          FrameProps={{
            sandbox:
              'allow-scripts allow-same-origin allow-downloads allow-popups allow-modals',
          }}
        />
      </PageTemplateResponsive>
    </CapabilitiesGuard>
  );
};

export default NotificationPreferences;
