import React, {
  Fragment,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useReducer,
} from 'react';

import {DateTime} from 'luxon';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import {Drawer, Grid, Typography, Divider, Box} from '@mui/material';
import CircleSliceIcon from '../../../shared/images/CircleSliceIcon';
import AlarmModeSection from './alarmModes/AlarmModeSection';
import ScheduleConfigurationSection from './scheduleConfiguration/ScheduleConfigurationSection';
import NotificationAccordion from './notifications/NotificationAccordion';
import AlarmSummarySection from './alarmSummary/AlarmSummarySection';
import withSnackbar from '../../../shared/components/snackbarSupport';
import {
  getDomainAlarmSchedules,
  deleteAlarmSchedule,
  getAlarmSummaryForSites,
} from '../../../api/alarms';
import CameraConfigurationSection from './cameraConfiguration/CameraConfigurationSection';
import SafetyButtonSection from './safetyButton/SafetyButtonSection';
import {useDialog} from '../../../shared/hooks';
import SchedulesList from './SchedulesList';
import SliderWidget from './SliderWidget';
import SitesAlarmsTable from './sitesAlarms/SitesAlarmsTable';
import CallbackFilterField from '../../../shared/components/callbackFilterField';
import {
  editSiteSubscription,
  getSiteNotificationSubscriptions,
} from '../../../api/notifications';
import {getCamerasBySiteId} from '../../../api/cameras';
import SelectList from '../../../shared/components/selectList';
import {AlarmSiteConfigurationContextProvider} from './AlarmSiteConfigurationContext';
import {subscriptionEventTypes} from './notifications/utils';

const sectionPadding = {
  paddingTop: 1,
  paddingLeft: 4,
  paddingRight: 4,
  paddingBottom: 3,
};

const alarmModes = ['Armed', 'Disarmed', 'Events Only', 'Test Mode'];

const filterReducer = (state, {type = '', value = ''}) => {
  switch (type) {
    case 'UPDATE_SEARCH': {
      const isValid = value.length > 2;
      return isValid
        ? {...state, searchValue: value, isSearchValueValid: true}
        : {...state, isSearchValueValid: false};
    }
    case 'RESET_SEARCH':
      return {
        ...state,
        searchValue: '',
        isSearchValueValid: true,
      };
    case 'UPDATE_ALARM_MODES_FILTER':
      return {
        ...state,
        alarmModesFilter: value,
      };
    default:
      return state;
  }
};

const AlarmConfigurationPageContent = (props) => {
  const {snackbar, currentUser} = props;

  const [siteSubscriptions, setSiteSubscriptions] = useState([]);
  const [selectedSite, setSelectedSite] = useState();
  const [domainSchedules, setDomainSchedules] = useState([]);
  const [scheduleUpdate, setScheduleUpdate] = useState(false);
  const [schedulesLoaded, setSchedulesLoaded] = useState(false);
  const [existingSiteSchedule, setExistingSiteSchedule] = useState();

  const [alarmModeUpdate, setAlarmModeUpdate] = useState();
  const [siteCameras, setSiteCameras] = useState();
  const [
    alarmModePrerequisitesUpdate,
    setAlarmModePrerequisitesUpdate,
  ] = useState();

  const [
    {isSearchValueValid, searchValue, alarmModesFilter},
    dispatch,
  ] = useReducer(filterReducer, {
    isSearchValueValid: true,
    searchValue: '',
    alarmModesFilter: [],
  });

  const [
    schedulesConfigurationOpen,
    handleSchedulesConfigurationOpen,
    handleSchedulesConfigurationClose,
  ] = useDialog();

  const [
    camerasConfigurationOpen,
    handleCamerasConfigurationOpen,
    handleCamerasConfigurationClose,
  ] = useDialog();

  const [
    notificationsDialogOpen,
    handleNotificationsDialogOpen,
    handleNotificationsDialogClose,
  ] = useDialog();

  const fetchSitesAlarmsData = useCallback(
    async (pageNum, limit, sortingColumn, sortingOrder) => {
      return getAlarmSummaryForSites(
        DateTime.local().minus({
          hours: 24,
        }),
        DateTime.local(),
        undefined,
        limit,
        pageNum * limit,
        false,
        true,
        searchValue,
        alarmModesFilter.length > 0 ? alarmModesFilter.join(',') : undefined,
        sortingColumn,
        sortingOrder,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scheduleUpdate, alarmModeUpdate, searchValue, alarmModesFilter],
  );

  useEffect(() => {
    const getSiteSubscriptions = async () => {
      setSiteSubscriptions(await getSiteNotificationSubscriptions());
    };

    getSiteSubscriptions();
  }, []);

  useEffect(
    () => {
      const getAlarmSchedules = async () => {
        setSchedulesLoaded(false);
        const schedules = await getDomainAlarmSchedules();

        setSchedulesLoaded(true);
        setDomainSchedules(schedules);
      };

      getAlarmSchedules();
    },
    [scheduleUpdate],
  );

  useEffect(
    () => {
      if (selectedSite) {
        getCamerasBySiteId(selectedSite.id).then(setSiteCameras);
      }
    },
    [selectedSite],
  );

  const handleDeleteSchedule = async (id) => {
    try {
      await deleteAlarmSchedule(id);
      // erase from state after succesfull DELETE request
      setDomainSchedules((currentSchedules) =>
        currentSchedules.filter((schedule) => schedule.id !== id),
      );

      snackbar.success('Schedule deleted successfully.');
    } catch (e) {
      snackbar.error('Error deleting schedule.', undefined, true);
    }
  };

  const uniqueSchedules = useMemo(
    () => sortBy(uniqBy(domainSchedules, 'name'), 'name'),
    [domainSchedules],
  );

  const handleSiteSelect = (site) =>
    setSelectedSite((prev) => {
      // guard for same site click
      if (prev?.id !== site.id) {
        setExistingSiteSchedule(null);
        setSiteCameras(null);
      }
      return site;
    });

  const handleFilterChange = useCallback((value) => {
    if (value.length) {
      dispatch({type: 'UPDATE_SEARCH', value});
    } else {
      dispatch({type: 'RESET_SEARCH'});
    }
  }, []);

  const unassignedSchedules = uniqueSchedules.filter((it) => !it.siteId);

  const handleChangeLateDeparturePeriod = async (
    subscription,
    quietMinutes,
  ) => {
    try {
      const payload = {
        ...pick(subscription, ['data', 'mediaProperties']),
        enable_quiet_seconds: 60 * quietMinutes,
      };
      await editSiteSubscription(
        subscription.siteId,
        subscription.uuid,
        payload,
      );
      snackbar.success('Late departure period updated successfully.');
    } catch (e) {
      snackbar.error('Error updating late departure period.', undefined, true);
    } finally {
      setSiteSubscriptions(await getSiteNotificationSubscriptions());
    }
  };

  const noonlightSubscription =
    selectedSite &&
    siteSubscriptions?.find(
      ({siteId, type, eventType}) =>
        siteId === selectedSite.id &&
        type === 'noonlight' &&
        eventType === subscriptionEventTypes.video,
    );

  const hasVideoSubscription =
    selectedSite &&
    siteSubscriptions?.find(
      ({siteId, eventType}) =>
        siteId === selectedSite.id &&
        eventType === subscriptionEventTypes.video,
    );
  return (
    <Fragment>
      <Box
        data-cy="alarm-configuration-page-content"
        sx={{
          display: 'flex',
          flex: '1 1',
          flexDirection: 'column',
          padding: 4,
          overflowY: 'auto',
        }}
      >
        <Grid
          container
          spacing={{xs: 0.5, sm: 2, md: 2, lg: 3, xl: 3}}
          justifyContent="flex-end"
          alignItems="flex-end"
          sx={{
            marginBottom: 1.5,
          }}
        >
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <SelectList
              id="alarmModesFilter"
              label="Alarm Mode"
              value={undefined}
              options={alarmModes.map((mode) => ({id: mode, name: mode}))}
              isMulti
              clearable
              onChange={(selectedValues) =>
                dispatch({
                  type: 'UPDATE_ALARM_MODES_FILTER',
                  value: selectedValues.map(({id}) => id),
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <CallbackFilterField
              sx={{width: '100%', margin: 0}}
              data-cy="site-schedule-search"
              isDebounced
              filterValue={searchValue}
              onFilter={handleFilterChange}
              error={!isSearchValueValid}
              helperText={
                isSearchValueValid ? null : 'Please enter at least 3 characters'
              }
            />
          </Grid>
        </Grid>
        <SitesAlarmsTable
          onSelectSite={handleSiteSelect}
          handleDataFetch={fetchSitesAlarmsData}
        />
        {!!unassignedSchedules.length && (
          <Box
            sx={{display: 'flex', flexDirection: 'column', maxHeight: '35vh'}}
          >
            <Box sx={(theme) => ({padding: theme.spacing(3, 1, 1, 0)})}>
              <Typography variant="subtitle2">Unassigned schedules</Typography>
            </Box>
            <SchedulesList
              schedules={unassignedSchedules}
              snackbar={snackbar}
              primaryTypographyProps={{variant: 'body2'}}
              onDelete={(id) => handleDeleteSchedule(id)}
              onRefresh={() => setScheduleUpdate((p) => !p)}
            />
          </Box>
        )}
      </Box>
      <Drawer
        variant="permanent"
        anchor="right"
        PaperProps={{
          sx: (theme) => ({
            width: 400,
            position: 'relative',
            zIndex: 1,
            ...theme.mixins.scrollable(
              currentUser.settings.newNavigationWeb ? theme.mixins.toolbar : 0,
            ),
          }),
        }}
      >
        <Fragment>
          {!selectedSite ? (
            <Box sx={{padding: 2}}>
              <Typography variant="h6" gutterBottom>
                Site Configuration
              </Typography>
              <Typography variant="subtitle1">
                Select a site to display and edit the configuration
              </Typography>
            </Box>
          ) : (
            <div>
              <AlarmSiteConfigurationContextProvider>
                <div>
                  <Typography
                    name="site-name"
                    sx={{paddingLeft: 2, paddingTop: 2}}
                    variant="h6"
                    gutterBottom
                  >
                    {selectedSite.name}
                  </Typography>
                </div>
                <Box name="alarm-summary" sx={sectionPadding}>
                  <AlarmSummarySection
                    selectedSiteId={selectedSite.id}
                    currentUser={currentUser}
                  />
                </Box>
                <Divider variant="middle" />
                <Box sx={sectionPadding} data-cy="alarm-mode-section">
                  <AlarmModeSection
                    selectedSite={selectedSite}
                    snackbar={snackbar}
                    onNotificationsConfigurationOpen={
                      handleNotificationsDialogOpen
                    }
                    onSchedulesConfigurationOpen={
                      handleSchedulesConfigurationOpen
                    }
                    onCamerasConfigurationOpen={handleCamerasConfigurationOpen}
                    onAlarmModeUpdate={setAlarmModeUpdate}
                    alarmModeFetchTrigger={alarmModePrerequisitesUpdate}
                  />
                </Box>
                <Divider variant="middle" />
                <Box name="site-configuration" sx={sectionPadding}>
                  <ScheduleConfigurationSection
                    schedules={uniqueSchedules}
                    selectedSite={selectedSite}
                    onSchedulesRefresh={() => setScheduleUpdate((p) => !p)}
                    domainSchedules={domainSchedules}
                    existingSiteSchedule={existingSiteSchedule}
                    setExistingSiteSchedule={setExistingSiteSchedule}
                    snackbar={snackbar}
                    schedulesLoaded={schedulesLoaded}
                    schedulesConfigurationOpen={schedulesConfigurationOpen}
                    onSchedulesConfigurationOpen={
                      handleSchedulesConfigurationOpen
                    }
                    onSchedulesConfigurationClose={() => {
                      handleSchedulesConfigurationClose();
                      setAlarmModePrerequisitesUpdate({});
                    }}
                  />
                </Box>
                {!!noonlightSubscription && (
                  <Fragment>
                    <Divider variant="middle" />
                    <Box
                      data-cy="late-departure-configuration"
                      sx={sectionPadding}
                    >
                      <SliderWidget
                        title="Late Departure"
                        helperText="This is providing the employees a grace period to leave the site before arming Smart Site Protection (SSP)"
                        icon={<CircleSliceIcon />}
                        value={noonlightSubscription.enableQuietSeconds / 60}
                        marksValues={[0, 1, 3, 5]}
                        step={1}
                        units="min"
                        onChange={(quietMinutes) =>
                          handleChangeLateDeparturePeriod(
                            noonlightSubscription,
                            quietMinutes,
                          )
                        }
                      />
                    </Box>
                  </Fragment>
                )}
                <Divider variant="middle" />
                <Box sx={sectionPadding}>
                  <NotificationAccordion
                    site={selectedSite}
                    isEnvysionMonitoring={!!noonlightSubscription}
                    hasVideoSubscription={!!hasVideoSubscription}
                    currentUser={currentUser}
                    notificationsDialogOpen={notificationsDialogOpen}
                    onNotificationsDialogOpen={handleNotificationsDialogOpen}
                    onNotificationsDialogClose={() => {
                      handleNotificationsDialogClose();
                      setAlarmModePrerequisitesUpdate({});
                    }}
                  />
                </Box>
                <Divider variant="middle" />
                <Box name="camera-configuration" sx={sectionPadding}>
                  <CameraConfigurationSection
                    siteCameras={siteCameras}
                    selectedSite={selectedSite}
                    snackbar={snackbar}
                    onSchedulesRefresh={() => setScheduleUpdate((p) => !p)}
                    currentUser={currentUser}
                    existingSiteSchedule={existingSiteSchedule}
                    camerasConfigurationOpen={camerasConfigurationOpen}
                    onCamerasConfigurationOpen={handleCamerasConfigurationOpen}
                    onCamerasConfigurationClose={() => {
                      handleCamerasConfigurationClose();
                      setAlarmModePrerequisitesUpdate({});
                    }}
                  />
                </Box>
                <Fragment>
                  <Divider variant="middle" />
                  <Box name="safety-button" sx={sectionPadding}>
                    <SafetyButtonSection
                      selectedSiteId={selectedSite.id}
                      selectedSiteName={selectedSite.name}
                      cameras={siteCameras}
                      snackbar={snackbar}
                    />
                  </Box>
                </Fragment>
              </AlarmSiteConfigurationContextProvider>
            </div>
          )}
        </Fragment>
      </Drawer>
    </Fragment>
  );
};

AlarmConfigurationPageContent.propTypes = {
  currentUser: PropTypes.shape({}).isRequired,
  snackbar: PropTypes.shape({}).isRequired,
};

export default withSnackbar(AlarmConfigurationPageContent);
