import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
} from '@mui/material';

import {ENVR_ADMIN} from '../../../../shared/util/allowed';
import CamerasList from './CamerasList';

const CameraConfigurationDialog = ({
  selectedSite,
  currentUser,
  existingSiteSchedule,
  handleCameraEnablementClick,
  handleEditCamera,
  camerasList,
  isVideoAlarmSupported,
  camerasConfigurationOpen,
  onCamerasConfigurationClose,
  dispatch,
}) => {
  const isEnvrAdmin = useMemo(
    () => currentUser.capabilities.some(({name}) => name === ENVR_ADMIN),
    [currentUser],
  );
  return (
    <Dialog
      data-cy="camera-enabled-modal"
      open={camerasConfigurationOpen}
      onClose={onCamerasConfigurationClose}
      PaperProps={{sx: {maxHeight: '80vh'}}}
    >
      <DialogTitle>Camera Enablement</DialogTitle>
      <DialogContent>
        <CamerasList
          site={selectedSite}
          isEnvrAdmin={isEnvrAdmin}
          siteHasSchedule={!!existingSiteSchedule}
          onCameraEnableClick={handleCameraEnablementClick}
          onCameraEdit={handleEditCamera}
          setCamerasList={(value) => {
            dispatch({
              type: 'update',
              payload: {camerasList: value},
            });
          }}
          camerasList={camerasList}
          isVideoAlarmSupported={isVideoAlarmSupported}
        />
      </DialogContent>
      {!isEnvrAdmin && (
        <Alert data-cy="admin-error-alert" sx={{m: 1}} severity="error">
          Camera enablement requires EnVR Administration capability.
        </Alert>
      )}
      <DialogActions>
        <Button
          name="camera-enablement-close-button"
          variant="text"
          onClick={onCamerasConfigurationClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CameraConfigurationDialog.propTypes = {
  selectedSite: PropTypes.shape({}),
  currentUser: PropTypes.shape({}).isRequired,
  existingSiteSchedule: PropTypes.shape({}),
  handleCameraEnablementClick: PropTypes.func.isRequired,
  handleEditCamera: PropTypes.func.isRequired,
  camerasList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isVideoAlarmSupported: PropTypes.bool.isRequired,
  camerasConfigurationOpen: PropTypes.bool.isRequired,
  onCamerasConfigurationClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

CameraConfigurationDialog.defaultProps = {
  selectedSite: {},
  existingSiteSchedule: null,
};
export default CameraConfigurationDialog;
