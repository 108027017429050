import React, {useEffect} from 'react';
import CloudQueue from '@mui/icons-material/CloudQueue';
import OpenInNewIcon from '@mui/icons-material/OpenInNewOutlined';
import {Alert} from '@mui/material';

import DashboardStatusWidget from '../../../../shared/components/dashboardComponent/dashboardStatusWidget';
import {useApi} from '../../../../shared/hooks';
import {getCloudArchiveStatusCount} from '../../../../api/cloudArchive';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import {isMobileApp} from '../../../../shared/util/general';

const CloudArchiveWidget = ({widgetConfig}) => {
  const [
    cloudArchiveStatusCount,
    cloudArchiveStatusCountLoading,
    isError,
    getStatusCount,
  ] = useApi(getCloudArchiveStatusCount);

  useEffect(
    () => {
      getStatusCount();
    },
    [getStatusCount],
  );

  const countCloudArchiveStatusesContent = () => {
    let errorCount = 0;
    let warningCount = 0;

    if (cloudArchiveStatusCount) {
      cloudArchiveStatusCount.forEach((element) => {
        if (element.status === 'ERROR') {
          errorCount = element.count;
        }
        if (element.status === 'WARNING') {
          warningCount = element.count;
        }
      });
    }
    return isError
      ? [
          {
            id: 'error-cloud-archive',
            label: <Alert severity="error">Error loading widget.</Alert>,
          },
        ]
      : [
          {
            id: 'failing-cloud-archives',
            label: 'Sites with Cloud Archive Failures:',
            value: errorCount.toString(),
          },
          {
            id: 'lagging-cloud-archives',
            label: 'Sites with Cloud Archive Warnings:',
            value: warningCount.toString(),
          },
        ];
  };

  return (
    <DashboardStatusWidget
      id={widgetConfig.widgetReference}
      content={countCloudArchiveStatusesContent(cloudArchiveStatusCount)}
      title="Cloud Archive"
      icon={CloudQueue}
      loading={cloudArchiveStatusCountLoading}
      onActionClick={() =>
        window.open('/ui/hardware-health?caStatus=error%2Cwarning')
      }
      buttonLabel="View Cloud Archive Details"
      buttonIcon={OpenInNewIcon}
      withActions={!isMobileApp()}
      {...widgetConfig.properties}
    />
  );
};

export default withCurrentUser(CloudArchiveWidget);
