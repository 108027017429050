import React, {useEffect} from 'react';
import {Link} from '@mui/material';
import compose from 'lodash/flowRight';
import DashboardSection from '../../../../shared/components/dashboardComponent/dashboardSection';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import {useApi} from '../../../../shared/hooks';
import {getRiskiestTransactions} from '../../../../api/reports';
import withSnackbar from '../../../../shared/components/snackbarSupport';
import {isNewNavigationWebEnabled} from '../../../../shared/util/user';

const RiskSnapshotHeaderWidget = ({currentUser, showButton, widgetConfig}) => {
  const [data, , , call] = useApi(getRiskiestTransactions);

  useEffect(
    () => {
      if (showButton) {
        call();
      }
    },
    [call, showButton],
  );
  const reportData = data || [];

  const getReportLink = () => {
    const alias = isNewNavigationWebEnabled(currentUser) ? 'my-reports/' : '';
    return currentUser.settings.enableNewReportsDetailPage
      ? `/ui/reports/${alias}${reportData[0]?.apiParams?.reportId}`
      : `/app/#/reports/${reportData[0]?.apiParams?.reportId}`;
  };

  return (
    <DashboardSection
      id={widgetConfig.widgetReference}
      title={widgetConfig.title || 'Risk Snapshot'}
      subtitle={widgetConfig.description || ''}
      actions={
        showButton &&
        reportData.length && [
          {
            id: 'link-button',
            label: 'See full Risk Report',
            ButtonProps: {
              component: Link,
              href: getReportLink(),
            },
          },
        ]
      }
      {...widgetConfig.properties}
    />
  );
};

export default compose(
  withCurrentUser,
  withSnackbar,
)(RiskSnapshotHeaderWidget);
