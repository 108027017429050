import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Icon from '@mui/material/Icon';
import AlarmIcon from '@mui/icons-material/Alarm';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import classNames from 'classnames';
import alarmModeIconStyles from '../alarmModeStyles';

const useStylesBase = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const useStyleAlarmModeIcon = makeStyles(alarmModeIconStyles);

export const getAlarmModeComponent = (status) => {
  switch (status) {
    case 'Test Mode':
      return (
        <AlarmModeIcon icon={<AlarmIcon />} label="Test Mode" level="warn" />
      );

    case 'Events Only':
      return (
        <AlarmModeIcon
          icon={<AccessTimeIcon />}
          label="Events Only"
          level="info"
        />
      );

    case 'Armed':
      return (
        <AlarmModeIcon icon={<AlarmOnIcon />} label="Armed" level="success" />
      );

    default:
      return (
        <AlarmModeIcon icon={<AlarmOffIcon />} label="Disarmed" level="error" />
      );
  }
};

const AlarmModeIcon = ({label, icon, level}) => {
  const baseClasses = useStylesBase();
  const alarmModeClasses = useStyleAlarmModeIcon();
  const classes = {...baseClasses, ...alarmModeClasses};
  return (
    <div data-cy="alarm-modes-row-component" className={classes.root}>
      <Icon
        data-cy="color-box"
        className={classNames(
          {
            [classes.colorWarning]: level === 'warn',
            [classes.colorError]: level === 'error',
            [classes.colorSuccess]: level === 'success',
            [classes.colorMain]: level === 'info',
          },
          classes.icon,
        )}
      >
        {icon}
      </Icon>
      {label}
    </div>
  );
};

export default AlarmModeIcon;
