import React, {createElement} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {getTabs} from './operationsRoutes';
import {getNavigationItems} from '../../shared/components/getNavigationItems';
import PageTemplateResponsive from '../../shared/components/pageTemplateResponsive';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';

const Operations = ({currentUser}) => {
  const navigationProps = getNavigationItems(getTabs, currentUser);
  return (
    <PageTemplateResponsive
      PageContentProps={{
        marginY: 0,
        marginX: 0,
      }}
      {...navigationProps}
    >
      <Switch>
        <Route exact path="/operations">
          <Redirect to="/operations/projects" />
        </Route>
        {getTabs.map((tab) => (
          <Route
            exact
            key={tab.key}
            path={`/operations/${tab.path}`}
            render={() => createElement(tab.component, tab.props)}
          />
        ))}
      </Switch>
    </PageTemplateResponsive>
  );
};
export default withCurrentUser(Operations);
