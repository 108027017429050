import React from 'react';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import IFrameWrapper from '../../shared/components/iFrameWrapper';
import {AUDIT, VIEW_PROJECTS} from '../../shared/util/allowed';
import {getHost} from '../../shared/components/iFrameWrapper/hostUtil';

const getIframeTab = (path, title, frameProps) => {
  return (
    <IFrameWrapper
      src={`https://${getHost()}/app/#/samples/${path}?embedded=true`}
      title={title}
      FrameProps={frameProps}
    />
  );
};

export const getTabs = [
  {
    key: 'projects',
    path: 'projects',
    primaryTitleText: 'Projects',
    component: () =>
      getIframeTab('projects', 'samples-projects', {
        sandbox:
          'allow-same-origin allow-scripts allow-downloads allow-popups allow-modals',
      }),
    icon: <DashboardOutlinedIcon />,
    capabilities: [VIEW_PROJECTS],
    preferences: [],
  },
  {
    key: 'audits',
    path: 'audits',
    primaryTitleText: 'Audits',
    component: () =>
      getIframeTab('auditor', 'samples-audits', {
        sandbox:
          'allow-scripts allow-same-origin allow-downloads allow-popups allow-modals',
      }),
    icon: <FactCheckOutlinedIcon />,
    capabilities: [AUDIT],
    preferences: [],
  },
];
