import React, {useEffect} from 'react';
import {useTheme, Button} from '@mui/material';
import {DateTime} from 'luxon';

import OpenInNewIcon from '@mui/icons-material/OpenInNewOutlined';
import {useApi} from '../../../../shared/hooks';

import DashboardWidget from '../../../../shared/components/dashboardComponent/dashboardWidget';
import DonutChartWidget from '../../../../shared/components/highcharts/donutChart';
import {checkPlatformSupport} from '../../../../shared/util/general';
import {
  alarmDispatches,
  alarmLabels,
} from '../../../../pages/sites/details/alarmEventsSummary/utils';
import {getAlarmStatisticsForSites} from '../../../../api/alarms';

import {
  getDonutChartData,
  getCategoryName,
  getColor,
} from '../../../../shared/util/ssp';

const currentTimestamp = DateTime.local().toUTC();
const weekAgoTimestamp = DateTime.local()
  .minus({
    day: 7,
  })
  .toUTC();

const TotalAlarmsWidget = ({widgetConfig}) => {
  const [alarms, alarmsLoading, , getAlarms] = useApi(
    getAlarmStatisticsForSites,
  );

  useEffect(
    () => {
      getAlarms(
        weekAgoTimestamp,
        currentTimestamp,
        [alarmDispatches.serviceContacted, alarmDispatches.canceled],
        [alarmLabels.eventsOnly],
        [alarmLabels.lateDeparture],
      );
    },
    [getAlarms],
  );
  const data = {
    no: alarms?.no ?? 0,
    yes: alarms?.yes ?? 0,
    unclassified: alarms?.unclassified ?? 0,
    unverifiable: alarms?.unverifiable ?? 0,
  };
  const rest = {
    ...checkPlatformSupport({
      supportsDesktop: true,
      minMobileVersion: '3.33.0',
    }),
  };

  const onActionClick = () => window.open(`/ui/ssp`);
  const theme = useTheme();

  const total = Object.values(data).reduce((acc, curr) => acc + curr, 0);

  const chartData = getDonutChartData(getCategoryName, getColor, data, theme);
  const chartSubtitle = `${total}`;

  return (
    <DashboardWidget
      title="Total Alarms"
      id={widgetConfig.widgetReference}
      loading={alarmsLoading}
      CardContentProps={{sx: {m: 0}}}
      CardProps={{sx: {padding: 2, justifyContent: 'center'}}}
      actions={[
        onActionClick && (
          <Button
            key="button"
            data-cy="view-details-total-alarms"
            onClick={onActionClick}
            sx={{color: theme.palette.primary.main}}
            endIcon={<OpenInNewIcon />}
          >
            View More Details
          </Button>
        ),
      ]}
      content={
        <DonutChartWidget
          data={chartData}
          graphSettings={{
            widgetName: 'total-alarms',
            height: '55%',
            subtitle: {
              text: !alarmsLoading ? chartSubtitle : '',
            },
            series: {
              fillColor: theme.palette.grey[400],
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  format: '{point.name} {point.y}',
                  filter: {
                    operator: '>',
                    property: 'y',
                    value: 0,
                  },
                  style: {
                    fontSize: '0.9rem',
                    color: theme.palette.default.contrastText,
                  },
                },
                enableMouseTracking: false,
                innerSize: '70%',
              },
            },
          }}
        />
      }
      {...widgetConfig.properties}
      {...rest}
    />
  );
};

export default TotalAlarmsWidget;
