import axios from 'axios';

const instance = axios.create({baseURL: '/nakika-service/api'});
instance.interceptors.request.eject(
  instance.interceptorIds?.requestCasingInterceptor,
);

const reportTitleEnum = Object.freeze({
  lineVoidStats: 'Line Void Stats',
  qsrRefundStats: 'QSR Refund Stats',
  qsrTransactions: 'QSR_Transactions',
  discountStats: 'Discount Stats',
  EmpDiscountStats: 'Employee Discount Stats',
  voidedItems: 'Voided Items',
  deletedItems: 'Deleted Items',
  cancelledItems: 'Cancelled Items',
});

export const getReports = async () => (await axios.get('/reports')).data;

export const getReportData = async (reportId) =>
  (await instance.get(`/reports/${reportId}`)).data;

export const getReportResults = async (reportId, params) =>
  (await instance.get(`/reports/${reportId}/results`, {
    params,
    paramsSerializer: {
      indexes: null, // by default: false
    },
  })).data;

export const getReportsExportData = async (reportId, params) => {
  const url = `/reports/${reportId}/results.csv`;
  const results = await instance.get(url, {
    params,
    paramsSerializer: {
      indexes: null,
    },
  });
  return results.data;
};

export const removeReport = async (reportId) =>
  (await instance.delete(`/reports/${reportId}`)).data;

export const updateReport = async (reportId, report) =>
  (await instance.put(`/reports/${reportId}`, report)).data;

export const createReport = async (report) =>
  (await instance.post(`/reports`, report)).data;

export const duplicateReport = async (reportId, report) =>
  (await axios.post(`/reports/${reportId}/duplicate`, report)).data;

export const getRiskiestEmployees = async () =>
  (await axios.get('reports/riskiest_employees', {
    params: {
      limit: 5,
      sorting_column: 'employee_avg_risk_weight',
      sorting_order: 'desc',
    },
  })).data?.results;

export const getRiskiestSites = async () =>
  (await axios.get('reports/riskiest_sites', {
    params: {
      limit: 5,
      sorting_column: 'site_avg_risk_weight',
      sorting_order: 'desc',
    },
  })).data?.results;

export const getRiskiestTransactions = async () =>
  (await axios.get('reports/riskiest_transactions', {
    params: {
      limit: 5,
      lookback: 7,
      sorting_column: 'risk_weight',
      sorting_order: 'desc',
    },
  })).data?.results;

export const getSiteByDayDataRefunds = async (userDate, lookBack, e) => {
  return (await axios.get('reports/sites_by_day', {
    params: {
      report_display_name: reportTitleEnum.qsrRefundStats,
      bi_site_id: e ? e.point.siteId : undefined,
      report_state_id: e ? e.point.nextStateId : undefined,
      calendar_day: userDate,
      look_back_days: lookBack,
    },
  })).data;
};

export const getSiteByDayDataVoidedItems = async (userDate, lookBack, e) => {
  return (await axios.get('reports/sites_by_day', {
    params: {
      report_display_name: reportTitleEnum.voidedItems,
      bi_site_id: e ? e.point.siteId : undefined,
      report_state_id: e ? e.point.nextStateId : undefined,
      calendar_day: userDate,
      look_back_days: lookBack,
    },
  })).data;
};

export const getSiteByDayDataDeletedItems = async (userDate, lookBack, e) => {
  return (await axios.get('reports/sites_by_day', {
    params: {
      report_display_name: reportTitleEnum.deletedItems,
      bi_site_id: e ? e.point.siteId : undefined,
      report_state_id: e ? e.point.nextStateId : undefined,
      calendar_day: userDate,
      look_back_days: lookBack,
    },
  })).data;
};

export const getSiteByDayDataCancelledItems = async (userDate, lookBack, e) => {
  return (await axios.get('reports/sites_by_day', {
    params: {
      report_display_name: reportTitleEnum.cancelledItems,
      bi_site_id: e ? e.point.siteId : undefined,
      report_state_id: e ? e.point.nextStateId : undefined,
      calendar_day: userDate,
      look_back_days: lookBack,
    },
  })).data;
};

export const getSiteByDayDataRemoved = async (userDate, lookBack, e) => {
  return (await axios.get('reports/sites_by_day', {
    params: {
      report_display_name: reportTitleEnum.lineVoidStats,
      bi_site_id: e ? e.point.siteId : undefined,
      report_state_id: e ? e.point.nextStateId : undefined,
      calendar_day: userDate,
      look_back_days: lookBack,
    },
  })).data;
};

export const getSiteByDayDataSummary = async (userDate, lookBack, e) => {
  return (await axios.get('reports/sites_by_day', {
    params: {
      report_display_name: reportTitleEnum.qsrTransactions,
      bi_site_id: e ? e.point.siteId : undefined,
      report_state_id: e ? e.point.nextStateId : undefined,
      sorting_column: 'calendar_id',
      sorting_order: 'asc',
      look_back_days: lookBack,
      calendar_day: userDate,
    },
  })).data;
};

export const getSiteByDayDiscountStats = async (userDate, lookBack, e) => {
  return (await axios.get('reports/sites_by_day', {
    params: {
      report_display_name: reportTitleEnum.discountStats,
      bi_site_id: e ? e.point.siteId : undefined,
      report_state_id: e ? e.point.nextStateId : undefined,
      calendar_day: userDate,
      look_back_days: lookBack,
    },
  })).data;
};

export const getSiteByDayRefundsManager = async (userDate, lookBack, e) => {
  return (await axios.get('reports/sites_appr_mgr_by_day', {
    params: {
      report_display_name: reportTitleEnum.qsrRefundStats,
      bi_site_id: e ? e.point.siteId : undefined,
      report_state_id: e ? e.point.nextStateId : undefined,
      look_back_days: lookBack,
      calendar_day: userDate,
    },
  })).data;
};

export const getSiteByDayEmpMgrDiscountStats = async (
  userDate,
  lookBack,
  e,
) => {
  return (await axios.get('reports/sites_emp_disc_by_day', {
    params: {
      report_display_name: reportTitleEnum.EmpDiscountStats,
      bi_site_id: e ? e.point.siteId : undefined,
      report_state_id: e ? e.point.nextStateId : undefined,
      calendar_day: userDate,
      look_back_days: lookBack,
    },
  })).data;
};

export const getSiteHierarchyData = async () =>
  (await axios.get('reports/site_hierarchy')).data;

export const getReviewStatusByTransIds = async (transactionIds) => {
  const queryStr = transactionIds.join('&id=');
  const {data} = await instance.get(`/transactions/metadata?id=${queryStr}`);

  const reviewStatus = Object.values(data).map((obj) => {
    return {
      transId: obj.transId,
      reviewed: obj.reviewAttributes.reviewed,
    };
  });

  return reviewStatus;
};

export const updateReviewStatusByTransId = async (data, transId, value) => {
  const valToStr = !!(value === 1 || value === 'true');
  const res = await instance.put(`/transactions/metadata/${transId}`, {
    ...data,
    reviewAttributes: {reviewed: valToStr},
  });
  return res;
};
