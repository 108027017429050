import React from 'react';
import compose from 'lodash/flowRight';
import {useTheme} from '@mui/material';
import {DateTime} from 'luxon';
import HighChartsWidget from '../../../../shared/components/highcharts';
import {getSiteByDayRefundsManager} from '../../../../api/reports';
import * as refundDollars from './widgetUtils/refundDollarsUtils';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import DashboardWidget from '../../../../shared/components/dashboardComponent/dashboardWidget';

const AprManagerRefundWidget = ({currentUser, widgetConfig}) => {
  const currentUserDate = DateTime.local()
    .toUTC()
    .toFormat('yyyyMMdd23');
  const reportLookBackDays = currentUser.settings.reportsDefaultDay || 1;

  const theme = useTheme();
  const getContent = () => {
    return (
      <HighChartsWidget
        id="highcharts-widget-refund-manager"
        getData={getSiteByDayRefundsManager}
        reportLookBackDays={reportLookBackDays}
        currentUserDate={currentUserDate}
        getDrilldownData={getSiteByDayRefundsManager}
        dualAxes={false}
        formatData={refundDollars.formatData}
        tooltipProvider={refundDollars.formatTooltip}
        graphSettings={{
          tipText: 'Approving Manager Refund Dollars:',
          chartType: 'column',
          seriesName: 'Refunds',
          title:
            widgetConfig.name ||
            'Approving Manager Refund Dollars as a Percentage of Sales',
          subtitle:
            widgetConfig.description ||
            'Approving manager refund dollars as a percentage of sales over the last seven days',
          yAxisTitle: 'Sales %',
          plotLinesLabel: 'avg refund dollars % sale',
          columnColor: theme.palette.primary.main,
          hoverColor: theme.palette.primary.light,
          widgetName: 'manager-refund-widget',
        }}
        extraOptions={refundDollars.extraOptions}
      />
    );
  };
  return (
    <DashboardWidget
      id={widgetConfig.widgetReference}
      title={widgetConfig.title}
      content={getContent()}
      {...widgetConfig.properties}
    />
  );
};

export default compose(withCurrentUser)(AprManagerRefundWidget);
