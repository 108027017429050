import React from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter, useLocation} from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import {MenuItem} from '@mui/material';

import {overrideComponent} from '../../../types/common';

const SecondNavSubListLink = (props) => {
  const {pathname} = useLocation();
  const {to, component, relative, match, children, onClick, bottom} = props;

  let incomingTo = to;

  if (!isEmpty(incomingTo) && !incomingTo.startsWith('/')) {
    incomingTo = `/${to}`;
  }
  const actualTo = relative ? `${match.url}${incomingTo}` : incomingTo;

  const isActive = (value) => pathname === value;

  const listItemStyles = (theme) => {
    const bottomStyle = {
      m: 0.5,
      p: 0.5,
      borderRadius: 2,
    };

    const style = {
      ...(bottom && bottomStyle),
      color: theme.palette.grey[800],
      '&:hover, &.Mui-hovered': {
        backgroundColor: theme.palette.primary.hover,
      },
    };

    return isActive(actualTo)
      ? {
          ...style,
          backgroundColor: theme.palette.primary.lightest,
          '& > div ': {
            color: theme.palette.primary.main,
            fontWeight: '500',
          },
        }
      : style;
  };

  return (
    <MenuItem
      data-cy="sidebar-sub-navigation-item"
      to={actualTo}
      component={component}
      sx={listItemStyles}
      onClick={onClick}
    >
      {children}
    </MenuItem>
  );
};

SecondNavSubListLink.propTypes = {
  /**
   * Node component to render navigation link
   */
  component: overrideComponent,
  /**
   * Node from parent to render
   */
  children: PropTypes.node.isRequired,
  /**
   * Determines whether the path is relative or not
   */
  relative: PropTypes.bool,
  /**
   * String containing path directing where to navigate on click
   */
  to: PropTypes.string.isRequired,
  /**
   * Property from withRouter, inform about url
   */
  match: PropTypes.shape({url: PropTypes.string}),
  /**
   *  Callback function handle on clicking sub nav item
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Boolean for positioning menu in bottom, below medium breakpoint (width 720px)
   */
  bottom: PropTypes.bool,
};

SecondNavSubListLink.defaultProps = {
  component: Link,
  relative: false,
  match: {url: ''},
  bottom: false,
};
export default withRouter(SecondNavSubListLink);
