import axios from 'axios';
import {omitBy, isEmpty, pick, snakeCase} from 'lodash';

export const updateableFieldNames = [
  'communicationProtocol',
  'displayOrder',
  'evaAnalyticUuid',
  'flipImage',
  'ipAddress',
  'keepAliveMessage',
  'motionMask',
  'name',
  'panomorphEnabled',
  'panomorphMount',
  'password',
  'port',
  'profile',
  'recordMode',
  'rtspUrl',
  'subRtspUrl',
  'unmanagedAllowArchive',
  'username',
  'fps',
  'quality',
  'resolutionId',
  'brightness',
  'hue',
  'contrast',
  'color',
  'sharpness',
  'isIp',
  'visible',
  'description',
];

export const buildPatchCameraBody = (camera) => {
  const fields = pick(camera, updateableFieldNames);

  // checks if value is empty (undefined, null, '', or empty object)
  const isEmptyValue = (val) =>
    !['number', 'boolean'].includes(typeof val) && isEmpty(val);

  // set fields that have a non-empty value
  const set = omitBy(fields, isEmptyValue);

  // unset fields that are defined but have an empty value
  // must convert unset field names to snakecase as axios does not convert strings
  const unset = updateableFieldNames
    .filter((f) => typeof fields[f] !== 'undefined' && isEmptyValue(fields[f]))
    .map((u) => snakeCase(u));

  return omitBy({set, unset}, isEmpty);
};

export const buildPutCameraBody = (camera) => {
  const body = {
    device_id: camera.deviceId,
    display_order: camera.displayOrder,
    ip_address: camera.ipAddress,
    is_ip: true,
    mfg: camera.make,
    model: camera.model,
    name: camera.name,
    panomorph_enabled: camera.fisheye,
    profile: camera.profile,
    rtsp_url: camera.rtspUrl,
    serial_number: camera.serial,
    supported_resolutions: camera.supportedResolutions,
    username: camera.username,
    password: camera.password,
  };
  return body;
};

export const buildPostCameraBody = (camera) => {
  const body = {
    appliance_id: camera.applianceId,
    device_id: camera.deviceId,
    display_order: camera.displayOrder,
    ip_address: camera.ipAddress,
    is_ip: true,
    mfg: camera.make,
    model: camera.model,
    name: camera.name,
    panomorph_enabled: camera.fisheye,
    profile: camera.profile,
    rtsp_url: camera.rtspUrl,
    serial_number: camera.serial,
    supported_resolutions: camera.supportedResolutions,
    username: camera.username,
    password: camera.password,
  };
  return body;
};

export const getResolutions = async () => {
  const {data} = await axios.get('cameras_resolutions');
  return data;
};

export const getCamerasBySiteId = async (siteId) =>
  (await axios.get('cameras', {
    params: {site_id: siteId},
  })).data;

export const getCamerasByApplianceId = async (applianceId) =>
  (await axios.get('cameras', {
    params: {appliance_id: applianceId},
  })).data;

export const patchCamera = async (camera) =>
  axios.patch(`cameras/${camera.id}`, buildPatchCameraBody(camera));

export const putCamera = async (camera) =>
  axios.put(`cameras/${camera.cameraId}`, buildPutCameraBody(camera));

export const postCamera = async (camera) =>
  axios.post('cameras', buildPostCameraBody(camera));

export const removeCamera = async (cameraId) => {
  return axios.delete(`cameras/${cameraId}`);
};

export const replaceCamera = async (
  cameraToReplaceId,
  replacementCam,
  addCredentials,
) => {
  const cam = {
    discoveredDeviceId: replacementCam.id,
    username:
      addCredentials && replacementCam.credentials
        ? replacementCam.credentials.username
        : undefined,
    password:
      addCredentials && replacementCam.credentials
        ? replacementCam.credentials.password
        : undefined,
    useIpAddress: replacementCam.useIp || undefined,
  };
  return (await axios.post(`cameras/${cameraToReplaceId}/replace`, cam)).data;
};

export const updateRegisterMapping = async (cameraId, registerIds) => {
  const {data} = await axios.put(`cameras/${cameraId}/registers`, {
    register_ids: registerIds,
  });
  return data;
};

export const updateTamperImgRef = async (time, cameraId, configBody) =>
  (await axios.put(`cameras/${cameraId}/reference_images/${time}`, configBody))
    .data;
