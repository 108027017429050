import React from 'react';
import PropTypes from 'prop-types';

import SecondNavSubSideList from './SecondNavSubSideList';
import SecondNavSubBottomList from './SecondNavSubBottomList';

const SecondNavSubList = ({
  subNavigationState,
  onSubNavClose,
  onSubNavItemClick,
  onMouseLeave,
  onMouseEnter,
  bottom,
}) => {
  return bottom ? (
    <SecondNavSubBottomList
      subNavigationState={subNavigationState}
      onSubNavItemClick={onSubNavItemClick}
      onSubNavClose={onSubNavClose}
    />
  ) : (
    <SecondNavSubSideList
      subNavigationState={subNavigationState}
      onSubNavClose={onSubNavClose}
      onSubNavItemClick={onSubNavItemClick}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    />
  );
};

SecondNavSubList.propTypes = {
  /**
   * State of bottom sub navigation item on smaller screens (width 720px)
   * This is only for bottom navigation elements
   */
  subNavigationState: PropTypes.shape({
    /**
     * Boolean for indicating if item is active
     */
    open: PropTypes.bool,
    /**
     *  Anchor element used to set position of sub menu popover
     */
    anchorEl: PropTypes.shape({}),
    /**
     * Array of sub navigation items (objects) to display in the pop up menu
     * Objects should contain:
     *
     * path - String containing path directing where to navigate on click
     *    Should contain path element which needs to be added to parent path
     *    Switch component in the same instance of pageTemplateResponsive should handle the same route
     *    Props is passed to NavLink component 'to' property
     *
     * capabilities - Array of strings defining capabilities
     *    a user needs to have to view this item in the navigation bar
     *
     * id - unique identifier
     *
     * name - identifying name for items. It's required that all subItems
     *   should have the same value as parent item
     *
     * icon - Node with icon to display in sub nav menu item
     *
     * primaryTitleText - String containing display name of sub nav menu item
     *   primary title is used on bottom item when no shortTitleText is provided
     *
     * shortTitleText - String containing display name of sub nav menu item
     *   short title is used on bottom item if provided
     */
    subNavItems: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string,
        capabilities: PropTypes.arrayOf(PropTypes.string),
        id: PropTypes.string,
        name: PropTypes.string,
        icon: PropTypes.node,
        primaryText: PropTypes.string,
        shortTitleText: PropTypes.string,
      }),
    ),
  }).isRequired,
  /**
   *  Callback function to handle clicks on sub-nav-items
   */
  onSubNavClose: PropTypes.func.isRequired,
  /**
   *  Callback function handle closing sub-nav-items
   */
  onSubNavItemClick: PropTypes.func.isRequired,
  /**
   *  Callback function handle on hover action when mouse leaves item area
   */
  onMouseLeave: PropTypes.func.isRequired,
  /**
   *  Callback function handle on hover action when mouse enters item area
   */
  onMouseEnter: PropTypes.func.isRequired,
  /**
   * Boolean for positioning menu in bottom, below medium breakpoint (width 720px)
   */
  bottom: PropTypes.bool.isRequired,
};

export default SecondNavSubList;
