import React from 'react';
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import FolderDeleteOutlinedIcon from '@mui/icons-material/FolderDeleteOutlined';
import IFrameWrapper from '../../shared/components/iFrameWrapper';
import {getHost} from '../../shared/components/iFrameWrapper/hostUtil';

const getIframeTab = (path, title, frameProps) => {
  return (
    <IFrameWrapper
      src={`https://${getHost()}/clips2/${path}?embedded=true`}
      title={title}
      FrameProps={frameProps}
    />
  );
};

export const getTabs = [
  {
    key: 'myClips',
    path: 'my-clips',
    primaryTitleText: 'My Clips',
    shortTitleText: 'Clips',
    component: () =>
      getIframeTab('root', 'My Clips', {
        sandbox: 'allow-same-origin allow-scripts allow-downloads',
      }),
    icon: <MovieOutlinedIcon />,
    capabilities: [],
    preferences: [],
  },
  {
    key: 'externalShares',
    path: 'external-shares',
    primaryTitleText: 'External Shares',
    shortTitleText: 'Shares',
    component: () =>
      getIframeTab('external-shares', 'External Shares', {
        sandbox: 'allow-scripts allow-same-origin allow-downloads allow-popups',
      }),
    icon: <ScreenShareOutlinedIcon />,
    capabilities: [],
    preferences: [],
  },
  {
    key: 'trash',
    path: 'trash',
    primaryTitleText: 'Trash',
    component: () =>
      getIframeTab('trash', 'Trash', {
        sandbox: 'allow-scripts allow-same-origin allow-downloads',
      }),
    icon: <FolderDeleteOutlinedIcon />,
    capabilities: [],
    preferences: [],
  },
];
