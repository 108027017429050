import React, {useState} from 'react';
import {DateTime} from 'luxon';
import {Box, Button, IconButton, Typography, Modal} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import CloseIcon from '@mui/icons-material/Close';

const EarliestVideoModal = ({cameras, isSmallScreen}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '10%',
    left: !isSmallScreen && 'calc(50% - 250px)',
    width: isSmallScreen ? '90vw' : 500,
    marginLeft: isSmallScreen && '5%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
  };

  const mobileButtonStyle = {
    fontSize: '0.82rem',
    padding: 0,
    margin: '0 4px',
  };

  const filteredCameras = cameras?.filter((cam) => cam.id !== null);
  const camerasOnline = cameras?.length === filteredCameras?.length;
  const columns = [
    {
      field: 'cameraName',
      headerName: 'Camera Name',
      flex: 1,
    },
    {
      field: 'earliestVideo',
      headerName: 'Earliest Video Date',
      flex: isSmallScreen ? 1 : 0.5,
      valueFormatter: (params) => {
        return params.value
          ? DateTime.fromISO(params.value).toFormat('DDD')
          : '--';
      },
    },
  ];
  return (
    <Box>
      <Button
        data-cy="storage-capacity-btn"
        sx={isSmallScreen ? mobileButtonStyle : null}
        variant={isSmallScreen ? 'text' : 'outlined'}
        onClick={handleOpen}
      >
        Per Camera
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          overflow: 'scroll',
        }}
      >
        <Box sx={style} data-cy="storage-capacity-modal">
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography
              className="earliest-video-modal-title"
              variant="h6"
              sx={{p: 1}}
            >
              {camerasOnline ? 'Storage Capacity Details' : 'No Cameras Online'}
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {camerasOnline && (
            <DataGridPro
              className="earliest-video-modal-grid"
              rows={cameras}
              columns={columns}
              disableColumnMenu
              disableColumnResize
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};
export default EarliestVideoModal;
