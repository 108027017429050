import React, {useEffect} from 'react';
import {DateTime} from 'luxon';
import VideocamOutlined from '@mui/icons-material/VideocamOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNewOutlined';
import {Alert} from '@mui/material';

import DashboardStatusWidget from '../../../../shared/components/dashboardComponent/dashboardStatusWidget';
import {useApi} from '../../../../shared/hooks';
import {getCamerasHealthSummary} from '../../../../api/hardwareHealth';

const CameraWidget = ({widgetConfig}) => {
  const [
    camerasHealthSummary,
    camerasHealthSummaryLoading,
    isError,
    getCamerasSummary,
  ] = useApi(getCamerasHealthSummary);

  useEffect(
    () => {
      const currentTimestamp = DateTime.utc().toISO();
      const dayAgoTimestamp = DateTime.utc()
        .minus({
          hours: 24,
        })
        .toISO();
      getCamerasSummary(dayAgoTimestamp, currentTimestamp);
    },
    [getCamerasSummary],
  );
  const count = camerasHealthSummary?.offline;
  const offlineChanges = camerasHealthSummary?.offlineChanges;
  const loading = camerasHealthSummaryLoading;

  const content = isError
    ? [
        {
          id: 'error-cameras',
          label: <Alert severity="error">Error loading widget.</Alert>,
        },
      ]
    : [
        {
          id: 'offline-cameras',
          label:
            count > 0 ? 'Total Offline Cameras:' : 'All cameras are online',
          value: count ? count.toString() : undefined,
        },
        {
          id: 'last-day-offline-cameras',
          label: count > 0 ? 'Offline cameras in the last 24 h:' : undefined,
          value: count > 0 ? offlineChanges.toString() : undefined,
        },
      ];

  return (
    <DashboardStatusWidget
      id={widgetConfig.widgetReference}
      content={content}
      title="Cameras"
      icon={VideocamOutlined}
      loading={loading}
      onActionClick={() =>
        window.open(`/ui/hardware-health?cameraStatus=offline`)
      }
      buttonLabel="View Camera Details"
      buttonIcon={OpenInNewIcon}
      {...widgetConfig.properties}
    />
  );
};

export default CameraWidget;
