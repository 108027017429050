import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

const Header = (props) => {
  const {
    order,
    orderBy,
    columns,
    onRequestSort,
    isExpandable,
    rowActions,
    fixedColumns,
  } = props;

  const createSortHandler = (property) => (event) => {
    if (typeof onRequestSort === 'function') {
      onRequestSort(event, property);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {isExpandable && (
          <TableCell style={{width: fixedColumns ? '5%' : null}} />
        )}
        {columns.map((column) => (
          <TableCell
            key={column.id}
            id={`sort-${column.id}`}
            sortDirection={orderBy === column.id ? order : false}
            style={column.style}
          >
            {onRequestSort ? (
              <TableSortLabel
                data-cy="ColumnLabel"
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
              </TableSortLabel>
            ) : (
              column.label
            )}
          </TableCell>
        ))}
        {rowActions && (
          <TableCell style={{width: fixedColumns ? '10%' : null}} />
        )}
      </TableRow>
    </TableHead>
  );
};

Header.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.node,
    }),
  ).isRequired,
  onRequestSort: PropTypes.func,
  isExpandable: PropTypes.bool,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  fixedColumns: PropTypes.bool,
};

Header.defaultProps = {
  onRequestSort: undefined,
  isExpandable: false,
  order: 'asc',
  orderBy: '',
  fixedColumns: false,
};

export default Header;
