import {useEffect, useCallback, useState} from 'react';

export const useSsp = (apiCall, startDate, endDate, onDataTransform) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const call = useCallback(
    async (...args) => {
      setLoading(true);
      try {
        const fetchedData = await apiCall(...args);
        let transformedData;
        if (onDataTransform) {
          transformedData = onDataTransform(fetchedData);
        }
        setData(transformedData ?? fetchedData);
        setLoading(false);
      } catch (e) {
        setError(e);
        if (e.code !== 'ERR_CANCELED') {
          setLoading(false);
        }
      }
    },
    [apiCall, onDataTransform],
  );

  useEffect(
    () => {
      const abortController = new AbortController();
      call(startDate, endDate, abortController.signal);

      return () => abortController.abort();
    },
    [call, endDate, startDate],
  );
  return [loading, data, error];
};
