import React from 'react';
import {Link} from '@mui/material';
import compose from 'lodash/flowRight';
import DashboardSection from '../../../../shared/components/dashboardComponent/dashboardSection';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import withSnackbar from '../../../../shared/components/snackbarSupport';

const SysHealthHeaderWidget = ({showButton, widgetConfig}) => {
  return (
    <DashboardSection
      id={widgetConfig.widgetReference}
      title={widgetConfig.title || 'System Health Status'}
      subtitle={
        widgetConfig.description ||
        'Track the Health of Your Video Surveillance Network'
      }
      actions={
        showButton && [
          {
            id: 'link_button',
            label: 'See full system health status',
            ButtonProps: {component: Link, href: '/ui/hardware-health'},
          },
        ]
      }
      {...widgetConfig.properties}
    />
  );
};

export default compose(
  withCurrentUser,
  withSnackbar,
)(SysHealthHeaderWidget);
