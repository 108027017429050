import React from 'react';
import {Link} from '@mui/material';
import compose from 'lodash/flowRight';
import DashboardSection from '../../../../shared/components/dashboardComponent/dashboardSection';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import withSnackbar from '../../../../shared/components/snackbarSupport';

const SalesInsightsHeaderWidget = ({showButton, widgetConfig}) => {
  return (
    <DashboardSection
      id={widgetConfig.widgetReference}
      title={widgetConfig.title || 'Sales Insights'}
      subtitle={widgetConfig.description || ''}
      actions={
        showButton && [
          {
            id: 'link-button',
            label: 'See full Intelligence Page',
            ButtonProps: {component: Link, href: '/ui/reports'},
          },
        ]
      }
      {...widgetConfig.properties}
    />
  );
};

export default compose(
  withCurrentUser,
  withSnackbar,
)(SalesInsightsHeaderWidget);
