import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {withRouter, useLocation} from 'react-router-dom';
import {Box, List, Divider, useMediaQuery, useTheme} from '@mui/material';
import {isEmpty} from 'lodash';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SecondNavListItem from './SecondNavListItem';
import SecondNavSubList from '../subNavigation/SecondNavSubList';
import {useNavigation} from '../../../hooks';

const moreButtonDefinition = {
  path: [],
  capabilities: [],
  id: 'more-button',
  name: 'more-button',
  icon: <MoreHorizIcon />,
  primaryTitleText: 'More',
};

const getSelectedMainMenu = (currentPath, navigationItems, match) => {
  let selectedSubMenuName;
  const mainMenuSelectedItem = navigationItems.find(
    (item) =>
      Array.isArray(item.path) &&
      item.subItems.find((subItem) => {
        let formattedSubItemPath = subItem.path;
        if (!isEmpty(subItem.path) && !subItem.path.startsWith('/')) {
          formattedSubItemPath = `/${subItem.path}`;
        }
        const isSubMenuSelected =
          currentPath === `${match.url}${formattedSubItemPath}`;
        if (isSubMenuSelected) {
          selectedSubMenuName = subItem.primaryTitleText;
        }
        return isSubMenuSelected;
      }),
  );
  return {
    isSubMenuOpen: !!mainMenuSelectedItem,
    subNavItems: mainMenuSelectedItem?.subItems,
    name: mainMenuSelectedItem?.name,
    subMenuSelectedName: selectedSubMenuName,
  };
};

const SecondNav = (props) => {
  const {
    navigationItems,
    isOpenedNavigation,
    bottom,
    match,
    drawerHeight,
    collapseDrawer,
  } = props;
  const bottomRef = useRef();
  const theme = useTheme();
  const {pathname} = useLocation();
  const isHorizontalView = useMediaQuery(
    '(min-width: 720px) and (orientation: landscape)',
  );
  const selectedSubMenu = getSelectedMainMenu(pathname, navigationItems, match);

  const [
    navItems,
    subNavigationState,
    selectedMainItemState,
    openSubNav,
    openSubBottomNav,
    closeSubNav,
    clickSubNavItem,
    clickMainNavItem,
    mouseEnter,
    mouseLeave,
  ] = useNavigation(
    navigationItems,
    moreButtonDefinition,
    bottom,
    {
      open: bottom && selectedSubMenu.isSubMenuOpen,
      anchorEl: bottom && bottomRef,
      subNavItems: bottom && selectedSubMenu.subNavItems,
      hoveredMainItem: null,
    },
    {
      name: selectedSubMenu?.name,
      secondaryText: selectedSubMenu?.subMenuSelectedName,
    },
  );

  const navSideItemsTop = navItems.filter(
    (item) => item.position === 'top' || !item.position,
  );
  const navSideItemsBottom = navItems.filter(
    (item) => item.position === 'bottom',
  );

  const navListItems = (items) =>
    items.map((item) => {
      const hasSubItems = item.subItems?.length > 0;
      const selected = selectedMainItemState.name === item.name;
      const hovered = subNavigationState.hoveredMainItem === item.name;
      const handleOpenSubNav = bottom
        ? () => openSubBottomNav(item.subItems, item.name)
        : (e) => openSubNav(e, item.subItems, item.name);

      return (
        <Box
          id={item.id}
          key={`nav-link-${item.path}`}
          sx={{width: '100%', mb: !isOpenedNavigation && !bottom ? 1 : 0}}
        >
          <SecondNavListItem
            primaryTitleText={item.primaryTitleText}
            shortTitleText={item.shortTitleText}
            icon={item.icon}
            to={item.path}
            bottom={bottom}
            secondary={
              hasSubItems && selected
                ? selectedMainItemState.secondaryText
                : null
            }
            onSubNavOpen={handleOpenSubNav}
            onMainNavItemClick={() => {
              if (!subNavigationState.subNavItems) {
                collapseDrawer();
              }
              clickMainNavItem();
            }}
            isHovered={hovered}
            isOpenedNavigation={isOpenedNavigation}
            onSubNavClose={closeSubNav}
            mouseEnter={mouseEnter}
            mouseLeave={mouseLeave}
            navItemsCount={navItems.length}
            redirectToNewTab={item.redirectToNewTab}
            redirectOutsideRoute={item.redirectOutsideRoute}
            relativePath={item.relativePath}
          />
        </Box>
      );
    });

  const bottomStyles = {
    display: 'flex',
    justifyContent: 'center',
    p: 0,
    width: '100%',
  };

  const navigation = (itemsBottom) => {
    const navItemsBoxHeight = `calc(${drawerHeight} - ${theme.mixins.sidebar
      .sidebarChevronButton.height +
      theme.mixins.sidebar.sidebarTopPadding.height}px)`;

    return (
      <List {...(bottom ? {sx: bottomStyles} : {sx: {height: '100%', p: 0}})}>
        {subNavigationState.subNavItems && (
          <SecondNavSubList
            subNavigationState={subNavigationState}
            onSubNavClose={closeSubNav}
            onSubNavItemClick={(text, name) => {
              collapseDrawer();
              clickSubNavItem(text, name);
            }}
            onMouseLeave={mouseLeave}
            onMouseEnter={mouseEnter}
            bottom={bottom}
          />
        )}
        {itemsBottom ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: isHorizontalView ? navItemsBoxHeight : '100%',
            }}
          >
            <Box
              sx={{
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
              data-cy="top-sidebar-box"
            >
              {navListItems(navSideItemsTop)}
            </Box>
            <Box
              sx={{
                position: 'sticky',
                bottom: 0,
              }}
            >
              <Divider data-cy="sidebar-divider" />
              {navListItems(navSideItemsBottom)}
            </Box>
          </Box>
        ) : (
          navListItems(navItems)
        )}
      </List>
    );
  };

  return bottom ? (
    <Box data-cy="bottom-navigation" ref={bottomRef}>
      {navigation()}
    </Box>
  ) : (
    navigation(navSideItemsBottom)
  );
};

SecondNav.propTypes = {
  /**
   * Array if menu items for second navigation:
   * id - unique identifier
   *
   * path - if there are no `subItems`, it should be a string;
   *        if `subItems` are present, it should be an array of strings
   *        that are present in subItems.path with the same order
   *
   * name - it's require that all subItems
   *        should have the same name as parent item
   *
   * primaryTitleText - default label for items
   *
   * shortTitleText - second label, used only if is present and only for bottom navigation
   *
   * icon - icon of menu item
   */
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      capabilities: PropTypes.arrayOf(PropTypes.string),
      id: PropTypes.string,
      name: PropTypes.string,
      icon: PropTypes.node,
      primaryTitleText: PropTypes.string,
      shortTitleText: PropTypes.string,
      subItems: PropTypes.arrayOf(
        PropTypes.shape({
          path: PropTypes.string,
          capabilities: PropTypes.arrayOf(PropTypes.string),
          id: PropTypes.string,
          name: PropTypes.string,
          icon: PropTypes.node,
          primaryTitleText: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  /**
   * Boolean for positioning menu in bottom, below medium viewport 720px.
   */
  bottom: PropTypes.bool,
  /**
   * Information if the navigation drawer is open / closed.
   */
  isOpenedNavigation: PropTypes.bool,
  /**
   * Height of the drawer itself required to calculate the height of the sidebar nav items container.
   */
  drawerHeight: PropTypes.string,
  /**
   * Callback function using to collapse drawer when item is clicked.
   */
  collapseDrawer: PropTypes.func,
};

SecondNav.defaultProps = {
  bottom: false,
  isOpenedNavigation: false,
  drawerHeight: '',
  collapseDrawer: () => {},
};

export default withRouter(SecondNav);
