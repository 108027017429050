import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import TheatersOutlinedIcon from '@mui/icons-material/TheatersOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import DoorBackOutlinedIcon from '@mui/icons-material/DoorBackOutlined';
import SwitchAccountOutlinedIcon from '@mui/icons-material/SwitchAccountOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import FiberDvrOutlinedIcon from '@mui/icons-material/FiberDvrOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

import React from 'react';
import {
  UPDATE_USER_PROFILE,
  BI_REPORT_2,
  USER_ADMIN,
  SECURITY_ADMINISTRATOR,
  GROUP_ADMIN,
  ENVR_ADMIN,
  POS_CONFIG,
  INTEGRATION_ADMIN,
  USER_STATISTICS_ACCESS,
  POS_DATA_ACCESS,
  BI_REPORT_ADMIN,
  PROJECT_ADMIN_CAPABILITY,
  DOMAIN_PREFERENCE,
  SKU_CATEGORY,
  PREFERENCE_ADMIN,
  DELEGATION_ADMIN,
  MOTION_ALARM_ADMIN,
  OPERATIONS_PORTAL,
  CLOUD_ARCHIVE_ADMIN,
  CLOUD_ARCHIVE_USER,
  VIDEO_ANALYTICS,
  INCIDENT_MANAGEMENT,
  MY_CLIPS,
  AUDIT,
  MOTION_ALARM_USER,
  SWITCH_USER,
  PLATFORM_ADMIN,
  THIRD_PARTY_INSTALLER,
} from '../../util/allowed';

export const workspaceItems = [
  {
    capabilities: [],
    preferences: [{newDashboard: true}],
    title: 'Dashboard',
    icon: <DashboardOutlinedIcon />,
    path: '/ui/dashboard',
  },
  {
    capabilities: [],
    preferences: [],
    title: 'Watch Video',
    icon: <PlayCircleFilledWhiteOutlinedIcon />,
    path: '/ui/sites',
  },
  {
    capabilities: [INCIDENT_MANAGEMENT],
    preferences: [],
    title: 'Incidents',
    icon: <OutlinedFlagIcon />,
    path: '/ui/incidents',
  },
  {
    capabilities: [BI_REPORT_2],
    preferences: [{enableNewReportLandingPage: true}],
    title: 'Intelligence',
    icon: <BarChartOutlinedIcon />,
    path: '/ui/reports',
  },
  {
    capabilities: [BI_REPORT_2],
    preferences: [{enableNewReportLandingPage: false}],
    title: 'Intelligence',
    icon: <BarChartOutlinedIcon />,
    path: '/app/#/reports',
  },
  {
    capabilities: [MY_CLIPS],
    preferences: [],
    title: 'Clips',
    icon: <TheatersOutlinedIcon />,
    path: '/ui/clips',
  },
  {
    capabilities: [AUDIT],
    preferences: [],
    title: 'Operations',
    icon: <AutoStoriesOutlinedIcon />,
    path: '/ui/operations',
  },
  {
    capabilities: [MOTION_ALARM_USER, MOTION_ALARM_ADMIN],
    preferences: [{enableSspStatusWebPage: true}],
    title: 'Security',
    icon: <GppGoodOutlinedIcon />,
    path: '/ui/ssp',
  },
  {
    capabilities: [],
    preferences: [{altaLink: true}],
    title: 'Access Control',
    icon: <DoorBackOutlinedIcon />,
    path: 'https://control.openpath.com/login',
    target: '_blank',
  },
];

export const userRoutes = (user) => [
  {
    path: `/ui/admin/users/${user.id}`,
    capabilities: [UPDATE_USER_PROFILE],
    preferences: [],
    icon: <PersonOutlinedIcon data-cy="user-icon" />,
    title: 'User Profile',
  },
  {
    path: '/ui/preferences/notifications',
    capabilities: [BI_REPORT_2],
    preferences: [],
    icon: <NotificationsNoneOutlinedIcon data-cy="notification-icon" />,
    title: 'Notification Settings',
  },
  user.impersonated
    ? {
        path: '/api/v3/sso/switch/revert',
        capabilities: [],
        preferences: [],
        icon: <SwitchAccountOutlinedIcon data-cy="switch-user-icon" />,
        title: 'Switch Back',
      }
    : {
        path: '/ui/admin/domains',
        capabilities: [SWITCH_USER],
        preferences: [],
        icon: <SwitchAccountOutlinedIcon data-cy="switch-user-icon" />,
        title: 'Switch User',
      },
  {
    path: '/api/v3/sso/logout',
    capabilities: [],
    preferences: [],
    icon: <ExitToAppOutlinedIcon data-cy="logout-icon" />,
    title: 'Logout',
  },
];

export const helpCenterRoutes = [
  {
    path: 'https://learning.envysion.com',
    capabilities: [],
    preferences: [],
    icon: <HelpOutlineIcon data-cy="learning-center-icon" />,
    title: 'Learning Center',
    openNewTab: true,
  },
  {
    path: '/ui/report-problem?envrName=',
    capabilities: [],
    preferences: [],
    icon: <ReportGmailerrorredOutlinedIcon data-cy="report-problem-icon" />,
    title: 'Report Problem',
  },
  {
    path: '/assets/secure/Envysion-Standard_Terms_and_Conditions.pdf',
    capabilities: [],
    preferences: [],
    icon: <DescriptionOutlinedIcon data-cy="description-icon" />,
    title: 'Terms & Conditions',
    openNewTab: true,
  },
];

export const administrationRoutes = [
  {
    path: '/ui/admin/users',
    capabilities: [USER_ADMIN],
    preferences: [],
    icon: <PersonOutlinedIcon data-cy="users-icon" />,
    title: 'Users',
  },
  {
    path: '/ui/admin/roles',
    capabilities: [USER_ADMIN],
    preferences: [],
    icon: <BadgeOutlinedIcon data-cy="roles-icon" />,
    title: 'Roles',
  },
  {
    path: '/ui/admin/accessList',
    capabilities: [USER_ADMIN],
    preferences: [],
    icon: <LockOutlinedIcon data-cy="access-icon" />,
    title: 'Access',
  },
  {
    path: '/ui/admin/authentication',
    capabilities: [SECURITY_ADMINISTRATOR],
    preferences: [],
    icon: <KeyOutlinedIcon data-cy="authentication-icon" />,
    title: 'Authentication',
  },
  {
    path: '/ui/admin/groups',
    capabilities: [GROUP_ADMIN],
    preferences: [],
    icon: <GroupOutlinedIcon data-cy="groups-icon" />,
    title: 'Groups',
  },
  {
    path: '/app/analytics',
    capabilities: [VIDEO_ANALYTICS],
    preferences: [],
    icon: <AnalyticsOutlinedIcon data-cy="analytics-icon" />,
    title: 'Analytics',
  },
  {
    path: '/admin/envrsList',
    capabilities: [ENVR_ADMIN],
    preferences: [{enableOldAdminEnvr: true}],
    icon: <GroupOutlinedIcon data-cy="envrs-icon" />,
    title: 'EnVRs',
  },
  {
    path: '/ui/admin/posList',
    capabilities: [POS_CONFIG],
    preferences: [],
    icon: <LocalAtmOutlinedIcon data-cy="pos-icon" />,
    title: 'POS',
  },
  {
    path: '/ui/admin/registers',
    capabilities: [POS_CONFIG],
    preferences: [],
    icon: <AddBoxOutlinedIcon data-cy="register-icon" />,
    title: 'Register',
  },
  {
    path: '/ui/admin/integrations',
    capabilities: [INTEGRATION_ADMIN],
    preferences: [],
    icon: <StorageOutlinedIcon data-cy="integrations-icon" />,
    title: 'Integrations',
  },
  {
    path: '/ui/admin/exportList',
    capabilities: [USER_STATISTICS_ACCESS, POS_DATA_ACCESS],
    preferences: [],
    icon: <IosShareOutlinedIcon data-cy="export-icon" />,
    title: 'Export',
  },
  {
    path: '/ui/admin/intelligence-configuration',
    capabilities: [BI_REPORT_ADMIN],
    preferences: [],
    icon: <BarChartOutlinedIcon data-cy="intelligence-configuration-icon" />,
    title: 'Intelligence Configuration',
  },
  {
    path: '/ui/admin/organization',
    capabilities: [ENVR_ADMIN],
    preferences: [{enableNewOrgScreen: true}],
    icon: <AccountTreeOutlinedIcon data-cy="organization-icon" />,
    title: 'Organization',
  },
  {
    path: '/admin/organization',
    capabilities: [ENVR_ADMIN],
    preferences: [{enableNewOrgScreen: false}],
    icon: <AccountTreeOutlinedIcon />,
    title: 'Organization',
  },
  {
    path: '/ui/samples/report',
    capabilities: [PROJECT_ADMIN_CAPABILITY],
    preferences: [],
    icon: <NoteAltOutlinedIcon data-cy="samples-icon" />,
    title: 'Samples',
  },
  {
    path: '/ui/admin/domain',
    capabilities: [DOMAIN_PREFERENCE],
    preferences: [],
    icon: <DomainOutlinedIcon data-cy="domain-icon" />,
    title: 'Domain',
  },
  {
    path: '/ui/admin/sku',
    capabilities: [SKU_CATEGORY],
    preferences: [],
    icon: <ShoppingCartOutlinedIcon data-cy="sku-icon" />,
    title: 'SKU Management',
  },
  {
    path: '/ui/admin/preferences',
    capabilities: [PREFERENCE_ADMIN],
    preferences: [],
    icon: <BuildOutlinedIcon data-cy="preferences-icon" />,
    title: 'Preferences',
  },
  {
    path: '/ui/admin/delegations',
    capabilities: [DELEGATION_ADMIN],
    preferences: [],
    icon: <AssignmentIndOutlinedIcon data-cy="delegations-icon" />,
    title: 'Delegations',
  },
  {
    path: '/ops/orders/list.php?web_auth=true',
    capabilities: [OPERATIONS_PORTAL],
    preferences: [],
    icon: <ReplyOutlinedIcon data-cy="installations-icon" />,
    title: 'Installations',
  },
  {
    path: '/ui/admin/cloud_archive_config',
    capabilities: [CLOUD_ARCHIVE_ADMIN, CLOUD_ARCHIVE_USER],
    preferences: [{enableCloudArchive2: true}],
    icon: <CloudUploadOutlinedIcon data-cy="cloud-archive-icon" />,
    title: 'Cloud Archive',
  },
];

export const platformAdminRoutes = [
  {
    path: '/ui/admin/appliances/list',
    capabilities: [PLATFORM_ADMIN, THIRD_PARTY_INSTALLER],
    preferences: [],
    icon: <DnsOutlinedIcon data-cy="appliances-icon" />,
    title: 'Appliances',
  },
  {
    path: '/ui/admin/partners',
    capabilities: [PLATFORM_ADMIN],
    preferences: [],
    icon: <SyncAltOutlinedIcon data-cy="partner-setup-icon" />,
    title: 'Partner Setup',
  },
  {
    path: '/ui/admin/cameras/makes/list',
    capabilities: [PLATFORM_ADMIN],
    preferences: [],
    icon: <VideocamOutlinedIcon data-cy="camera-makes-models-icon" />,
    title: 'Camera Makes/Models',
  },
  {
    path: '/ui/admin/platform/domains',
    capabilities: [PLATFORM_ADMIN],
    preferences: [],
    icon: <DomainOutlinedIcon data-cy="domains-icon" />,
    title: 'Domains',
  },
  {
    path: '/ui/admin/dvrs/makes/list',
    capabilities: [PLATFORM_ADMIN],
    preferences: [],
    icon: <FiberDvrOutlinedIcon data-cy="dvr-makes-models-icon" />,
    title: 'DVR Makes/Models',
  },
  {
    path: '/ui/admin/templates/list',
    capabilities: [PLATFORM_ADMIN],
    preferences: [],
    icon: <FileCopyOutlinedIcon data-cy="templates-icon" />,
    title: 'Templates',
  },
];
