import React, {Fragment} from 'react';
import {Typography, Button, Box} from '@mui/material';
import PropTypes from 'prop-types';
import {kebabCase} from 'lodash';

import DashboardWidget from '../dashboardWidget';

const getRowStyle = (rowType) => {
  switch (rowType) {
    case 'main':
      return {fontWeight: 600};
    case 'secondary':
      return {color: (theme) => theme.palette.text.secondary};
    default:
      return undefined;
  }
};
const getContent = (data, isSmallScreen) => (
  <Fragment>
    {data &&
      data.map((row) => (
        <Box
          data-cy="widget-content-line"
          id={row.id}
          key={row.id}
          alignItems="center"
          display="flex"
        >
          {isSmallScreen ? (
            <Typography
              sx={{
                display: 'flex',
                flex: 1,
                marginRight: (theme) => theme.spacing(1.5),
              }}
              variant="caption"
            >
              {row.label}
            </Typography>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                marginRight: (theme) => theme.spacing(1.5),
                minHeight: 32,
              }}
            >
              {row.label}
            </Box>
          )}
          {row.value !== undefined ? (
            <Typography
              sx={{
                marginLeft: (theme) => theme.spacing(1.5),
                ...{...getRowStyle(row.type)},
              }}
              data-cy="widget-content-line-data"
              variant="subtitle1"
            >
              {row.value}
            </Typography>
          ) : null}
        </Box>
      ))}
  </Fragment>
);

const DashboardStatusWidget = ({
  content,
  title,
  icon,
  loading,
  buttonIcon,
  onActionClick,
  buttonLabel,
  withActions,
  isSmallScreen,
  ...rest
}) => {
  return (
    <DashboardWidget
      id={kebabCase(title)}
      title={title}
      icon={icon && React.createElement(icon, {'data-cy': 'widget-icon'})}
      loading={loading}
      content={getContent(content, isSmallScreen)}
      {...withActions && {
        actions: (
          <Button
            data-cy="widget-action"
            onClick={onActionClick}
            endIcon={
              buttonIcon &&
              React.createElement(buttonIcon, {
                'data-cy': 'widget-action-icon',
              })
            }
            sx={{color: (theme) => theme.palette.primary.main}}
          >
            {buttonLabel}
          </Button>
        ),
      }}
      isSmallScreen={isSmallScreen}
      {...rest}
    />
  );
};

DashboardStatusWidget.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.elementType,
  loading: PropTypes.bool.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      value: PropTypes.string,
    }),
  ).isRequired,
  onActionClick: PropTypes.func,
  buttonIcon: PropTypes.elementType,
  buttonLabel: PropTypes.string,
  withActions: PropTypes.bool,
  isSmallScreen: PropTypes.bool,
};
DashboardStatusWidget.defaultProps = {
  title: undefined,
  icon: undefined,
  buttonIcon: undefined,
  onActionClick: undefined,
  buttonLabel: undefined,
  withActions: true,
  isSmallScreen: false,
};
export default DashboardStatusWidget;
