import React, {createElement} from 'react';
import {Route, Switch} from 'react-router-dom';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import PageTemplateResponsive from '../../shared/components/pageTemplateResponsive';
import {getTabs} from './ClipsPillarRoutes';
import {getNavigationItems} from '../../shared/components/getNavigationItems';
import {RedirectWithQueryParams} from '../../shared/components/redirectWithQueryParams';

const ClipsPageResponsive = ({currentUser}) => {
  const navigationProps = getNavigationItems(getTabs, currentUser);

  return (
    <PageTemplateResponsive
      PageHeaderProps={{title: 'Clips'}}
      {...navigationProps}
    >
      <Switch>
        <Route exact path="/clips">
          <RedirectWithQueryParams to="/clips/my-clips" />
        </Route>
        {getTabs.map((tab) => (
          <Route
            exact
            key={tab.key}
            path={`/clips/${tab.path}`}
            render={() => createElement(tab.component, tab.props)}
          />
        ))}
      </Switch>
    </PageTemplateResponsive>
  );
};

export default withCurrentUser(ClipsPageResponsive);
