import React, {useCallback, useEffect, useRef} from 'react';
import {useTheme} from '@mui/material';
import {merge, cloneDeep} from 'lodash';
import PropTypes from 'prop-types';
import drilldown from 'highcharts/modules/drilldown';
import HighCharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighChartsReact from 'highcharts-react-official';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import {defaultHighChartsOptionsJson} from './highChartsOptionsJson';

const createDefaultHighChartsJson = (
  getDrilldownData,
  graphSettings,
  tooltipProvider,
  theme,
) => {
  return Object.assign(
    {},
    {
      chart: {
        type: graphSettings.chartType,
        events: {
          drilldown: (e) => {
            return e.point.nextStateId && getDrilldownData(e);
          },
        },
      },
      title: {
        text: graphSettings.title,
        margin: parseFloat(theme.spacing(5)),
      },
      subtitle: {
        text: graphSettings.subtitle,
        style: {
          color: theme.palette.primary.light,
          fontSize: theme.typography.caption.fontSize,
        },
      },
      xAxis: {
        title: graphSettings.xAxisTitle,
      },
      yAxis: {
        title: {
          text: graphSettings.yAxisTitle,
        },
      },
      plotOptions: {
        // General settings for all series
        series: {},
        // General settings for columns
        column: {
          minPointLength: 5,
          color: graphSettings.columnColor,
          pointPadding: parseFloat(theme.spacing(0)),
          states: {
            hover: {
              color: graphSettings.hoverColor,
            },
          },
          point: {
            events: {
              click() {
                if (this.options && this.options.url) {
                  window.open(this.options.url, '_blank');
                }
              },
            },
          },
        },
      },
    },
    // If tooltipProvider exist we will add it to the json, else default tooltip
    tooltipProvider
      ? {
          tooltip: {
            formatter: function tooltipWrapper() {
              return tooltipProvider(this, graphSettings.tipText);
            },
            useHTML: true,
          },
        }
      : {},
  );
};

const createHighChartJson = (
  getDrilldownData,
  extraOptions,
  graphSettings,
  tooltipProvider,
  theme,
) => {
  const initialJson = createDefaultHighChartsJson(
    getDrilldownData,
    graphSettings,
    tooltipProvider,
    theme,
  );

  const extraOptionsJson = extraOptions ? extraOptions(theme) : {};

  return merge(
    cloneDeep(defaultHighChartsOptionsJson),
    cloneDeep(extraOptionsJson),
    cloneDeep(initialJson),
  );
};

const useHighchartsApi = (
  apiFn,
  reportLookBackDays,
  currentUserDate,
  chartComponent,
  formatData,
  graphSettings,
  formatDualAxes,
  isDrilldown = false,
  theme,
) => {
  return useCallback(
    async (...args) => {
      const {
        current: {chart},
      } = chartComponent;
      chart.showLoading();

      try {
        await apiFn(currentUserDate, reportLookBackDays, ...args).then(
          (response) => {
            const {results} = response;
            const formattedResults = formatData
              ? results.map((element) => {
                  return formatData(element);
                })
              : results;
            if (isDrilldown) {
              const {
                point,
                point: {name},
              } = args[0];
              chart.addSeriesAsDrilldown(point, {
                name,
                data: formattedResults,
              });
            } else {
              chart.addSeries({
                data: formattedResults,
              });
              if (formatDualAxes) {
                chart.addSeries(formatDualAxes(results, theme));
              }

              chart.yAxis[0].addPlotLine({
                value: response.avgValue,
                dashStyle: 'dot',
                color: 'gray',
                label: {
                  text: graphSettings.plotLinesLabel,
                  align: 'right',
                  x: -10,
                  style: {
                    fontSize: 12,
                    pointerEvents: 'none',
                  },
                },
                zIndex: 4,
              });
            }

            if (formattedResults.length === 0) {
              chart.showNoData();
            }
          },
        );
      } catch (e) {
        if (!isDrilldown) {
          chart.hideNoData();
          chart.showNoData('Error loading data');
        }
      } finally {
        chart.hideLoading();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiFn, chartComponent, formatData, isDrilldown],
  );
};

export default function HighChartsWidget(props) {
  const chartComponent = useRef(null);

  const theme = useTheme();
  const {
    getData,
    reportLookBackDays,
    currentUserDate,
    getDrilldownData,
    formatData,
    formatDualAxes,
    extraOptions,
    graphSettings,
    tooltipProvider,
  } = props;
  // Obtains top-level data
  const getDataCall = useHighchartsApi(
    getData,
    reportLookBackDays,
    currentUserDate,
    chartComponent,
    formatData,
    graphSettings,
    formatDualAxes,
    false,
    theme,
  );

  // Obtains drill-down data
  const getDrilldownDataCall = useHighchartsApi(
    getDrilldownData,
    reportLookBackDays,
    currentUserDate,
    chartComponent,
    formatData,
    graphSettings,
    formatDualAxes,
    true,
    theme,
  );

  // Creates options json for highcharts
  const chartOptionsJson = createHighChartJson(
    getDrilldownDataCall,
    extraOptions,
    graphSettings,
    tooltipProvider,
    theme,
  );

  drilldown(HighCharts);
  NoDataToDisplay(HighCharts);
  highchartsAccessibility(HighCharts);

  // Get Top level data
  useEffect(() => {
    getDataCall().then(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HighChartsReact
      id={`${graphSettings.widgetName}-id`}
      key={graphSettings.widgetName}
      ref={chartComponent}
      highcharts={HighCharts}
      options={chartOptionsJson}
    />
  );
}

HighChartsWidget.propTypes = {
  graphSettings: PropTypes.shape({
    chartType: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    yAxisTitle: PropTypes.string,
    xAxisTitle: PropTypes.string,
    plotLinesLabel: PropTypes.string,
    columnColor: PropTypes.string,
    hoverColor: PropTypes.string,
    widgetName: PropTypes.string,
  }).isRequired,
  getData: PropTypes.func.isRequired,
  getDrilldownData: PropTypes.func,
  formatData: PropTypes.func,
  formatDualAxes: PropTypes.func,
  extraOptions: PropTypes.func,
  tooltipProvider: PropTypes.func,
  reportLookBackDays: PropTypes.number,
  currentUserDate: PropTypes.string.isRequired,
};

HighChartsWidget.defaultProps = {
  getDrilldownData: null,
  formatData: null,
  formatDualAxes: null,
  extraOptions: null,
  tooltipProvider: null,
  reportLookBackDays: 0,
};
