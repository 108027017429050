import {React, useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  TableFooter,
  TableContainer,
  Paper,
  Backdrop,
  useMediaQuery,
} from '@mui/material';
import {useTheme} from '@mui/styles';
import isEmpty from 'lodash/isEmpty';
import PaginationActions from '../sortableTable/PaginationActions';
import Spinner from '../spinner';
import Header from './Header';
import Row from './Row';

const ORDER_DESCENDING = 'desc';
const ORDER_ASCENDING = 'asc';
const DEFAULT_RECORDS_PER_PAGE = 5;

const SortableHeader = (props) => {
  const {
    columns,
    isExpandable,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    rowActions,
    sortable,
    setAuxOptions,
  } = props;

  const handleRequestSort = (_, property) => {
    const requestedOrderProperty = property;
    let orderToSet = ORDER_ASCENDING;
    if (orderBy === requestedOrderProperty && order === ORDER_ASCENDING) {
      orderToSet = ORDER_DESCENDING;
    }
    // reset aux options whenever apply sorting manually by column click
    if (setAuxOptions) {
      setAuxOptions(null);
    }
    setOrder(orderToSet);
    setOrderBy(requestedOrderProperty);
  };

  return (
    <Header
      isExpandable={isExpandable}
      columns={columns}
      order={order}
      orderBy={orderBy}
      onRequestSort={sortable ? handleRequestSort : undefined}
      rowActions={rowActions}
      fixedColumns // column widths are fixed by the the first row of cells
    />
  );
};

const CollapsibleTable = (props) => {
  const {
    name,
    onLoadData,
    onRowClick,
    columns,
    onExpandCollapsibleData,
    rowId,
    rowActions,
    sortable,
    paginateOptions,
    stickyHeader,
    selectedId,
    orderOptions,
    auxOptions,
    setAuxOptions,
  } = props;

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(
    paginateOptions.initialRecords ?? DEFAULT_RECORDS_PER_PAGE,
  );

  const [page, setPage] = useState(0);
  const handleSetPage = (_, value) => {
    setPage(value);
  };

  const handleSetRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const [order, setOrder] = useState(orderOptions.initialOrder ?? undefined);
  const [orderBy, setOrderBy] = useState(
    orderOptions.initialOrderBy ?? undefined,
  );

  const [loading, setLoading] = useState(false);

  const isExpandable = onExpandCollapsibleData !== undefined;

  const columnsSpan = () => {
    let columnsSpanLength = columns.length;
    if (isExpandable) columnsSpanLength += 1;
    if (rowActions) columnsSpanLength += 1; // single cell holds all actions

    return columnsSpanLength;
  };

  useEffect(
    () => {
      if (auxOptions) {
        setOrder(auxOptions.order);
        setOrderBy(auxOptions.orderBy);
      }
    },
    [auxOptions],
  );

  useEffect(
    () => {
      let ignore = false;

      async function fetchData() {
        setLoading(true);
        const dataFetched = await onLoadData(page, rowsPerPage, order, orderBy);
        if (dataFetched && !ignore) {
          setData(dataFetched.results);
          setDataCount(dataFetched.count);
        }
        if (!ignore) {
          setLoading(false);
        }
      }
      fetchData();

      return () => {
        ignore = true;
      };
    },
    [onLoadData, page, rowsPerPage, order, orderBy],
  );

  const isSelected = (id) => selectedId === id;

  const renderRows = () =>
    data
      .filter((row) => row !== undefined)
      .map((row) => (
        <Row
          key={rowId ? row[rowId] : row.id}
          columns={columns}
          rowData={row}
          onExpandCollapsibleData={onExpandCollapsibleData}
          onRowClick={onRowClick}
          rowActions={rowActions}
          columnsSpan={columnsSpan()}
          selected={isSelected(rowId ? row[rowId] : row.id)}
        />
      ));

  // handle recalculating the backdrop area
  const tableRef = useRef(null);
  const [tableScrollWidth, setTableScrollWidth] = useState(null);
  useEffect(() => {
    const updateWidth = () => {
      if (tableRef.current) {
        const {scrollWidth} = tableRef.current;
        setTableScrollWidth(scrollWidth);
      }
    };
    updateWidth();
    // event listener for viewport changes
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  return (
    <TableContainer component={Paper} sx={{position: 'relative'}}>
      <Backdrop
        aria-label="collapsible table backdrop"
        open={loading}
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          position: 'absolute',
          zIndex: theme.zIndex.appBar + 1,
          width: tableScrollWidth,
        }}
      >
        <Spinner size={40} />
      </Backdrop>
      <Table
        ref={tableRef}
        aria-label="collapsible table"
        stickyHeader={stickyHeader}
      >
        <SortableHeader
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          columns={columns}
          isExpandable={isExpandable}
          rowActions={rowActions}
          sortable={sortable}
          setAuxOptions={setAuxOptions}
        />

        <TableBody>
          {isEmpty(data) && !loading ? (
            <TableRow>
              <TableCell colSpan={columnsSpan()}>No results found</TableCell>
            </TableRow>
          ) : (
            renderRows()
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              data-cy="TablePagination"
              count={dataCount}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              page={page}
              onPageChange={handleSetPage}
              onRowsPerPageChange={handleSetRowsPerPage}
              ActionsComponent={
                !paginateOptions.useBasicNavigation
                  ? PaginationActions
                  : undefined
              }
              SelectProps={{
                name: `${name}-count-select`,
                'data-cy': 'TablePaginationCountSelect',
              }}
              labelRowsPerPage={isSmallDevice ? '' : 'Rows per page:'}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default CollapsibleTable;

CollapsibleTable.propTypes = {
  name: PropTypes.string,
  /**
   * Columns definition array. Id and label fields are required. Additional formatting is optional
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
      format: PropTypes.func,
    }),
  ).isRequired,
  /**
   * Callback function that renders the content of collabsible row.
   * If not provided - non-collapsible table would be used instead.
   */
  onExpandCollapsibleData: PropTypes.func,
  /**
   * Callback to handle api call for server-side data with following params:
   * page, rowsPerPage, order, orderBy.
   */
  onLoadData: PropTypes.func,
  /**
   * Callback to perform some side effects when clicking the row.
   */
  onRowClick: PropTypes.func,
  /**
   * Pagination options for setting the initial records (number of rows) in the table and
   * boolean property for basic navigation. If set to true only next and previous page buttons
   * will appear in the navigation bar. By default there are additional buttons to navigate to
   * first and last page in the table.
   */
  paginateOptions: PropTypes.shape({
    initialRecords: PropTypes.number,
    useBasicNavigation: PropTypes.bool,
  }),
  /**
   * OrderOptions which allow to set initial order (asc/desc) and initial column by which
   * we will order by (initialOrderBy)
   */
  orderOptions: PropTypes.shape({
    initialOrder: PropTypes.string,
    initialOrderBy: PropTypes.string,
  }),
  /**
   * auxOptions which allow to change current order options from outside components
   */
  auxOptions: PropTypes.shape({
    order: PropTypes.string,
    orderBy: PropTypes.string,
  }),
  /**
   * setAuxOptions allows to change current state of auxOptions
   */
  setAuxOptions: PropTypes.func,
  /**
   * The unique identifier for row (by default it is "id" field)
   */
  rowId: PropTypes.string,
  /**
   * Array of possible additional actions applied for each row.
   */
  rowActions: PropTypes.arrayOf(PropTypes.func),
  /**
   * Parameter indicating whether the table has the sorting feature off or no.
   * If set to true column names in header will have sorting icons visible on hover
   * (clickable action icons)
   */
  sortable: PropTypes.bool,

  /**
   * Whether the header should be sticky or not. Default value is true.
   */
  stickyHeader: PropTypes.bool,
  /**
   * Id of clicked row that should be highlighted
   */
  selectedId: PropTypes.string,
};

CollapsibleTable.defaultProps = {
  name: '',
  onLoadData: undefined,
  onExpandCollapsibleData: undefined,
  onRowClick: undefined,
  paginateOptions: {
    initialRecords: 5,
    useBasicNavigation: false,
  },
  orderOptions: {
    initialOrder: undefined,
    initialOrderBy: undefined,
  },
  auxOptions: undefined,
  setAuxOptions: undefined,
  rowId: '',
  rowActions: undefined,
  sortable: false,
  stickyHeader: true,
  selectedId: '',
};

SortableHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
      format: PropTypes.func,
    }),
  ).isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  setOrder: PropTypes.func,
  setOrderBy: PropTypes.func,
  handleAdditionalData: PropTypes.func,
  isExpandable: PropTypes.bool,
  rowActions: PropTypes.arrayOf(PropTypes.func),
  sortable: PropTypes.bool,
  setAuxOptions: PropTypes.func,
};

SortableHeader.defaultProps = {
  order: ORDER_ASCENDING,
  orderBy: '',
  setOrder: undefined,
  setOrderBy: undefined,
  handleAdditionalData: undefined,
  isExpandable: false,
  rowActions: undefined,
  sortable: false,
  setAuxOptions: undefined,
};
