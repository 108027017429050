import React from 'react';
import {Link} from 'react-router-dom';
import {AppBar, Toolbar, Typography, Tooltip, IconButton} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import withStyles from '@mui/styles/withStyles';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.primary.light,
    zIndex: theme.zIndex.drawer + 1,
  },
  backLink: {
    color: theme.palette.common.white,
  },
  appBarWithDrawerButton: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  drawerButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    color: theme.palette.common.white,
  },
});

const PageHeader = (props) => {
  const {
    classes,
    title,
    actions,
    backLink,
    component,
    displayDrawerButton,
    onToggleTempDrawer,
  } = props;

  return (
    <AppBar id="appBar" className={classes.appBar}>
      <Toolbar
        className={displayDrawerButton ? classes.appBarWithDrawerButton : ''}
      >
        {backLink && (
          <Tooltip title="Go back">
            <IconButton
              component={Link}
              to={backLink}
              className={classes.backLink}
              aria-label="Back button"
              name="back-button"
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        )}
        {displayDrawerButton && (
          <Tooltip title="Drawer">
            <IconButton
              onClick={onToggleTempDrawer}
              aria-label="Drawer button"
              name="drawer-button"
              size="large"
              data-cy="drawer-button"
              className={classes.drawerButton}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
        )}
        {component || (
          <Typography variant="h6" component="h1" color="inherit">
            {title}
          </Typography>
        )}

        <div style={{flexGrow: 1}} />
        {actions}
      </Toolbar>
    </AppBar>
  );
};

PageHeader.propTypes = {
  title: PropTypes.node.isRequired,
  actions: PropTypes.node,
  backLink: PropTypes.string,
  component: PropTypes.node,
  displayDrawerButton: PropTypes.bool,
  onToggleTempDrawer: PropTypes.func,
};

PageHeader.defaultProps = {
  actions: null,
  backLink: null,
  component: null,
  displayDrawerButton: false,
  onToggleTempDrawer: null,
};

PageHeader.muiName = 'AppBar';

export default withStyles(styles)(PageHeader);
