import React from 'react';
import IFrameWrapper from '../../shared/components/iFrameWrapper';
import {getHostEnv} from '../../shared/util/general';
import {incidentsHosts} from '../../shared/components/navigationBar/utils';
import PageTemplateResponsive from '../../shared/components/pageTemplateResponsive';

const environment = getHostEnv();

const IncidentsWrapper = () => (
  <PageTemplateResponsive
    PageContentProps={{
      marginY: 0,
      marginX: 0,
    }}
  >
    <IFrameWrapper
      src={`${incidentsHosts[environment] ??
        'https://tsappdev1.envysion.com/account'}?embedded=true`}
      title="incidents"
      FrameProps={{
        sandbox: 'allow-scripts allow-same-origin allow-forms allow-modals',
      }}
    />
  </PageTemplateResponsive>
);

export default IncidentsWrapper;
