import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Fragment, useState} from 'react';
import {kebabCase} from 'lodash';
import Spinner from '../spinner';

const IFrameWrapper = ({src, title, FrameProps}) => {
  const [loading, setLoading] = useState(true);
  const [cacheValue] = useState(new Date().getTime());

  const onFinishLoad = () => setLoading(false);
  const srcUrl = `${src}${src.includes('?') ? '&' : '?'}cache=${cacheValue}`;

  return (
    <Fragment>
      <Box
        sx={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {loading && <Spinner size={40} />}
      </Box>
      <iframe
        title={title}
        src={srcUrl.toString()}
        width="100%"
        height="100%"
        allowFullScreen
        style={{border: 0}}
        onLoad={onFinishLoad}
        data-cy={`${kebabCase(title)}-iframe-content`}
        {...FrameProps}
      />
    </Fragment>
  );
};

IFrameWrapper.propTypes = {
  /**
   *   Should contain full path of page to display inside frame
   */
  src: PropTypes.string.isRequired,
  /**
   *   IFrame element should have title.
   *   Title should  be unique to easily find iframe in component tree
   */
  title: PropTypes.string.isRequired,
  /**
   *   Optional styling props for iFrame
   */
  FrameProps: PropTypes.shape({}),
};

IFrameWrapper.defaultProps = {
  FrameProps: {},
};

export default IFrameWrapper;
