import React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import compose from 'lodash/flowRight';
import GlobalErrorPage from '../globalErrorPage';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import PageTemplateResponsive from '../../shared/components/pageTemplateResponsive';
import DashboardApp from '../../apps/dashboard';

import {sspTheme} from '../../shared/themes';

const Dashboard = ({currentUser}) => {
  if (currentUser.settings.newDashboard) {
    return (
      <ThemeProvider theme={sspTheme}>
        <PageTemplateResponsive
          PageHeaderProps={{title: 'Dashboard'}}
          title="Dashboard"
        >
          <DashboardApp dashboardId={1} width={100} />
        </PageTemplateResponsive>
      </ThemeProvider>
    );
  } // width fix the MUI 5 Grid negative margin issue
  return <GlobalErrorPage />;
};

export default compose(withCurrentUser)(Dashboard);
