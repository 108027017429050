import {useState, useCallback, useRef, useEffect} from 'react';
import set from 'lodash/set';
import {getSites} from '../api/sites';
import {flattenSitesListForSearch} from './util/sites';

// Custom hook for maintaining dialog open/close state
export const useDialog = (defaultOpen = false) => {
  const [open, setOpen] = useState(defaultOpen);

  const handleOpen = useCallback(
    () => {
      setOpen(true);
    },
    [setOpen],
  );

  const handleClose = useCallback(
    () => {
      setOpen(false);
    },
    [setOpen],
  );

  return [open, handleOpen, handleClose];
};

// Custom hook for dropdown selector state management
export const useSelector = (
  initialValue,
  property = 'name',
  separator = ',',
) => {
  const [filterValues, setfilterValues] = useState(initialValue);

  const setAdaptedValues = (values) => {
    const queryValues = values.map((val) => val[property]).join(separator);
    setfilterValues(queryValues);
  };

  return [filterValues, setAdaptedValues];
};

export const useInterval = (callback, delay, dependencies = []) => {
  const savedCallback = useRef();

  // Remember the latest callback;
  useEffect(
    () => {
      savedCallback.current = callback;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, ...dependencies],
  );

  // Set up the interval
  useEffect(
    () => {
      function tick(id) {
        savedCallback.current(id);
      }

      const id = setInterval(() => tick(id), delay);
      // interval cleared on unmount
      return () => clearInterval(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [delay, ...dependencies],
  );
};

// pagination hook statuses
export const paginationStatus = Object.freeze({
  Idle: 'Idle',
  Loading: 'Loading', // fetching more data (offset > 0)
  Reloading: 'Reloading', // re-fetching data (offset = 0)
  Error: 'Error',
  Terminated: 'Terminated', // all entries have already been fetched
});
// custom hook for pagination
export const useListPaginator = (
  apiHandler,
  initPage = 0,
  paginationLimit = 50,
) => {
  const totalCount = useRef(null);
  const pageRef = useRef(initPage);
  const allDataFetched = useRef(false);

  const [listData, setListData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(paginationStatus.Idle);

  const onLoadMoreData = useCallback(
    async (resetData = false, abortControllerSignal) => {
      // further execution prohibited in following state(s)
      if (currentStatus === paginationStatus.Loading) {
        return;
      }

      if (resetData) {
        pageRef.current = 0;
        allDataFetched.current = false;
        setCurrentStatus(paginationStatus.Reloading);
      } else setCurrentStatus(paginationStatus.Loading);

      try {
        const newData = await apiHandler(
          paginationLimit,
          pageRef.current * paginationLimit,
          abortControllerSignal,
        );
        if (newData) {
          const {results, count} = newData;
          totalCount.current = count;
          setListData((currentList) => {
            const updatedList = resetData
              ? results
              : [...currentList, ...results];
            if (
              updatedList.length === count ||
              results.length < paginationLimit
            )
              allDataFetched.current = true;
            else pageRef.current += 1;
            return updatedList;
          });
          setCurrentStatus(
            allDataFetched.current
              ? paginationStatus.Terminated
              : paginationStatus.Idle,
          );
        }
      } catch (error) {
        if (error.message !== 'canceled') {
          setCurrentStatus(paginationStatus.Error);
        }
      }
    },
    [apiHandler, currentStatus, paginationLimit],
  );

  return [currentStatus, listData, onLoadMoreData, totalCount];
};

export const useValidation = (schema) => {
  const [errors, setErrors] = useState({});
  const [valid, setValid] = useState(true);

  const validate = useCallback(
    (obj) => {
      let v = true;
      let e = {};
      try {
        schema.validateSync(obj, {abortEarly: false});
      } catch (err) {
        const validationErrors = err.inner.reduce((acc, error) => {
          const [fieldError] = error.errors;
          set(acc, error.path, fieldError);
          return acc;
        }, {});
        v = false;
        e = validationErrors;
      }
      setValid(v);
      setErrors(e);
      return {
        valid: v,
        errors: e,
      };
    },
    [schema, setErrors],
  );

  return [valid, errors, validate];
};

export const useApi = (apiFn) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const call = useCallback(
    async (...args) => {
      setLoading(true);
      try {
        await apiFn(...args).then(setData);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    },
    [apiFn],
  );

  return [data, loading, error, call];
};

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

// according to mobile guidelines - we should not have more than 5 items in the bottom navigation bar
const MAX_BOTTOM_NAV_BAR_ITEMS = 5;
// for handling sidebar navigation on the responsive page template
export const useNavigation = (
  navigationItems = [],
  moreButtonDefinition = {
    path: [],
    capabilities: [],
    id: 'more-button',
    name: 'more-button',
    icon: null,
    primaryText: 'More',
  },
  bottom = false,
  navigationState = {
    open: false,
    anchorEl: null,
    subNavItems: null,
    hoveredMainItem: null,
  },
  selectedItemState = {name: null, secondaryText: null},
  closingTimeout = 1000,
) => {
  const [navItems, setNavItems] = useState(navigationItems);
  const shouldRenderMoreButton =
    bottom && navigationItems.length > MAX_BOTTOM_NAV_BAR_ITEMS;

  const [subNavigationState, setSubNavigationState] = useState(navigationState);
  const [selectedMainItemState, setSelectedMainItemState] = useState(
    selectedItemState,
  );
  const closeTimeoutRef = useRef(null);
  const clearCloseTimeout = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
      closeTimeoutRef.current = null;
    }
  };

  const openSubNav = (e, items, itemName) => {
    clearCloseTimeout();
    if (items) {
      setSubNavigationState({
        open: true,
        anchorEl: e.currentTarget,
        subNavItems: items,
        hoveredMainItem: itemName,
      });
    }
  };

  const openSubBottomNav = (items, itemName) =>
    setSubNavigationState((prev) => ({
      anchorEl: prev.anchorEl,
      open: true,
      subNavItems: items,
      hoveredMainItem: itemName,
    }));

  const clickSubNavItem = (text, name) => {
    setSelectedMainItemState({name, secondaryText: text});
    setSubNavigationState((prev) => ({
      open: bottom,
      anchorEl: bottom ? prev.anchorEl : null,
      subNavItems: bottom ? prev.subNavItems : null,
      hoveredMainItem: null,
    }));
  };

  const clickMainNavItem = () =>
    setSelectedMainItemState((prev) => ({
      ...prev,
      secondaryText: subNavigationState.subNavItems ? prev.secondaryText : null,
    }));

  const mouseEnter = () => clearCloseTimeout();

  const mouseLeave = () => {
    clearCloseTimeout();
    closeTimeoutRef.current = setTimeout(() => {
      setSubNavigationState({
        open: false,
        anchorEl: null,
        subNavItems: null,
        hoveredMainItem: null,
      });
    }, closingTimeout);
  };

  const closeSubNav = () => {
    clearCloseTimeout();
    setSubNavigationState((prev) => ({
      open: false,
      anchorEl: bottom ? prev.anchorEl : null,
      subNavItems: null,
      hoveredMainItem: null,
    }));
  };

  /* eslint-disable no-param-reassign */
  useEffect(() => {
    if (shouldRenderMoreButton) {
      setNavItems((prev) => {
        const mainItems = prev.slice(0, MAX_BOTTOM_NAV_BAR_ITEMS - 1);
        let rest = prev
          .slice(MAX_BOTTOM_NAV_BAR_ITEMS - 1)
          .map((r) => ({...r, name: moreButtonDefinition.name}))
          .reverse();
        if (rest.some((r) => r.subItems)) {
          // eslint-disable-next-line no-console
          console.warn(`
          Dear developer, you are trying to include an item with sub-items in the collapsed "More" section.
          Currently, we do not support nested popover menus and this item will be filtered out.
          Kindly relocate this item within the first ${MAX_BOTTOM_NAV_BAR_ITEMS -
            1} positions in the navigation bar.
          For further guidance, please refer to the Storybook documentation.`);
          rest = rest.filter((r) => !r.subItems);
        }
        moreButtonDefinition.path = rest.map((r) => r.path);
        moreButtonDefinition.subItems = rest;
        return [...mainItems, moreButtonDefinition];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* eslint-enable no-param-reassign */

  useEffect(
    () => {
      if (subNavigationState.anchorEl?.current !== null) {
        setSubNavigationState((prev) => ({
          ...prev,
          anchorEl: subNavigationState.anchorEl,
        }));
      }
      return () => {
        if (!bottom) clearCloseTimeout();
      };
    },
    [bottom, subNavigationState.anchorEl],
  );

  return [
    navItems,
    subNavigationState,
    selectedMainItemState,
    openSubNav,
    openSubBottomNav,
    closeSubNav,
    clickSubNavItem,
    clickMainNavItem,
    mouseEnter,
    mouseLeave,
  ];
};

// to handle click out
export const useOnClickOutside = (ref, anchorRef, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        if (
          !ref.current ||
          ref.current.contains(event.target) ||
          (anchorRef.current && anchorRef.current.contains(event.target))
        ) {
          return;
        }
        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, anchorRef, handler],
  );
};

export const useSiteSelector = (urlState = {siteId: ''}, setUrlState) => {
  const [sites, setSites] = useState();
  const [selectedSite, setSelectedSite] = useState({
    id: urlState.siteId || '',
    name: '',
  });
  const [reload, setReload] = useState(null);

  const handleSiteSelect = (e) => {
    setSelectedSite({id: e.currentSite, name: e.name});
    setReload(new Date());
  };
  useEffect(() => {
    const handleGetSites = async () => {
      const sitesList = await getSites();
      setSites(sitesList);
    };
    handleGetSites();
  }, []);

  useEffect(
    () => {
      const handleSelectedSite = async () => {
        const site = await flattenSitesListForSearch(sites, sites?.name).find(
          (s) => s.id === urlState.siteId,
        );
        setSelectedSite({
          id: urlState.siteId,
          name: site?.name,
        });
      };
      if (urlState.siteId) {
        handleSelectedSite();
      }
    },
    [sites, urlState.siteId],
  );

  useEffect(
    () => {
      if (setUrlState) {
        setUrlState({siteId: selectedSite.id, alarmId: undefined});
      }
    },
    [selectedSite.id, setUrlState],
  );

  return [selectedSite, sites, reload, handleSiteSelect];
};
