import {isNewNavigationWebEnabled} from '../../util/user';

export const getNavigationItems = (tabs, currentUser) => {
  const navigationItems = tabs.map((tab) => ({
    path: tab.path,
    id: tab.navId,
    name: tab.label,
    icon: tab.icon,
    primaryTitleText: tab.primaryTitleText,
    shortTitleText: tab.shortTitleText,
  }));
  return isNewNavigationWebEnabled(currentUser) ? {navigationItems} : {};
};
