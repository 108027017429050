import React from 'react';
import GlobalErrorPage from '../globalErrorPage';
import CapabilitiesGuard from '../../shared/components/capabilitiesGuard';
import IFrameWrapper from '../../shared/components/iFrameWrapper';
import PageTemplateResponsive from '../../shared/components/pageTemplateResponsive';
import {PROJECT_ADMIN_CAPABILITY} from '../../shared/util/allowed';
import {getHost} from '../../shared/components/iFrameWrapper/hostUtil';

const samplesReportUrl = `https://${getHost()}/app/#/samples/report?embedded=true`;

const SamplesReport = () => (
  <CapabilitiesGuard
    allowed={[PROJECT_ADMIN_CAPABILITY]}
    renderDefault={() => <GlobalErrorPage error="NoAuth" />}
  >
    <PageTemplateResponsive
      PageContentProps={{
        marginY: 0,
        marginX: 0,
      }}
    >
      <IFrameWrapper
        src={samplesReportUrl}
        title="samples-report"
        FrameProps={{
          sandbox:
            'allow-scripts allow-same-origin allow-downloads allow-popups allow-modals',
        }}
      />
    </PageTemplateResponsive>
  </CapabilitiesGuard>
);

export default SamplesReport;
